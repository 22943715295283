import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Button,
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as Actions from "store/actions";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import "./posStyle.css";

const style = {
  proMain: {
    minHeight: "400px",
    maxHeight: "400px",
    overflowY: "auto",
    overflowX: "auto",
    padding: "10px 0px",
    width: "100%",
    margin: "0",
  },
  footer: {
    minHeight: "100px",
    maxHeight: "100px",
    background: "#fbfbfb",
    padding: "10px 0px",
  },
  h5s: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  btns: {
    width: "100%",
    textAlign: "right",
    padding: "20px",
  },
  listLis: {
    padding: "2px 0px",
    fontSize: "15px",
    fontWeight: "600",
  },
};
function PosSystem(props) {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [pay, setPay] = useState(0);
  const [custId, setCustId] = useState(1);
  const [openM, setOpenM] = useState(false);
  const [changeItemId, setChangeItemId] = useState(null);
  const [priceModal, setPriceModal] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(null);
  const listData = useSelector(({ app }) => app.Pos);
  const customerBalnceData = useSelector(({ app }) => app.CustomerBalance);
  const posLoading = useSelector(({ app }) => app.posLoading);
  useEffect(() => {
    if (listData) {
      setList(listData);
    }
  }, [listData]);
  useEffect(() => {
    if (customerBalnceData) {
      setCustomerBalance(customerBalnceData);
    }
  }, [customerBalnceData]);
  const toggle = () => {
    if (list.length > 0) {
      setPay(0);
      setOpenM(!openM);
    } else {
      toast.warn("No Items in list");
    }
  };
  const CustChange = (e) => {
    let custIdParsed = JSON.parse(e.target.value);
    setCustId(custIdParsed);
  };
  const handleCustomerBalnce = (e) => {
    e.preventDefault();
    if (list.length === 0) {
      return;
    } else if (custId === 1) {
      return dispatch(Actions.bringNullBalnce());
    } else {
      dispatch(Actions.setPosLoading(true));
      dispatch(Actions.bringBalace(custId));
    }
  };
  const Qtyplus = (id) => {
    dispatch(Actions.QtyPlus(id));
  };
  const Qtyminus = (id) => {
    dispatch(Actions.QtyMinus(id));
  };
  const removeItem = (id) => {
    dispatch(Actions.removeItem(id));
  };
  const changeQuantityHandler = (id, e) => {
    let data = {
      id,
      quantity: e.target.value ? JSON.parse(e.target.value) : 1,
    };
    dispatch(Actions.changeQuantity(data));
  };
  const payNow = (e) => {
    setPay(e.target.value);
  };
  let Qty = 0;
  let netPrice = 0;
  if (list && list.length > 0) {
    list.map((idx) => {
      Qty += JSON.parse(idx.quantity);
      netPrice += JSON.parse(idx.salePrice) * JSON.parse(idx.quantity);
      return null;
    });
  }
  let balance = 0;
  if (netPrice - pay > -1) {
    balance = netPrice - pay;
  }
  let changeRe = 0;
  if (pay - netPrice > -1) {
    changeRe = pay - netPrice;
  }

  const priceModalToggle = () => {
    setPriceModal(!priceModal);
  };
  const changePrice = (e) => {
    e.preventDefault();
    let data = {
      id: changeItemId,
      salePrice: e.target.price.value,
    };
    dispatch(Actions.changePrice(data));
    priceModalToggle();
  };
  const calculatePaidAmount = () => {
    return pay > customerBalance.totalDueAmount + netPrice
      ? customerBalance.totalDueAmount + netPrice
      : pay;
  };
  const placeOrder = (e) => {
    e.preventDefault();
    if (custId === 1 && pay < netPrice) {
      toast.warn("Dues not clear");
    } else {
      let data = {
        totalPrice: netPrice,
        paidAmount: calculatePaidAmount(),
        customer_id: custId,
        pendingBalance:
          JSON.parse(customerBalance.totalDueAmount) +
          JSON.parse(netPrice) -
          JSON.parse(calculatePaidAmount()),
        products: list.map((idx) => ({
          id: idx.id,
          quantity: idx.quantity,
          rate: idx.salePrice,
        })),
      };
      dispatch(Actions.placeOrder(data));
      toggle();
    }
  };

  return (
    <>
      <Container style={{ border: "1px solid #e5e5e5" }}>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <select
              name="customer"
              className="form-control"
              onChange={(e) => CustChange(e)}
              required
            >
              <option value={1}>Walkin Customer</option>
              {props.customers && props.customers !== null ? (
                props.customers.map((cust) => (
                  <option value={cust.id} key={cust.id}>
                    {cust.fullName}
                  </option>
                ))
              ) : (
                <option value="cat.id">wait</option>
              )}
            </select>
          </Col>
        </Row>
        <Row style={style.proMain}>
          <div className="table-responsive">
            <Table className="cart-table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Stock</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Remove</th>
                </tr>
                {list !== null && list.length > 0 ? (
                  list.map((idx) => (
                    <tr key={idx.id}>
                      <td>{idx.name}</td>
                      <td>{idx.stock}</td>
                      <td className="product-quantity">
                        <div className="quantity">
                          <input
                            type="number"
                            min={0}
                            max={999}
                            className="input-text qty text"
                            id="hide-arrow-input"
                            value={idx.quantity}
                            title="Qty"
                            onChange={(e) => {
                              changeQuantityHandler(idx.id, e);
                            }}
                          />
                          <div className="quantity-nav">
                            <Link
                              className="quantity-button quantity-up"
                              to="#"
                              onClick={(e) => Qtyplus(idx.id)}
                            >
                              +
                            </Link>
                            <Link
                              className="quantity-button quantity-down"
                              to="#"
                              onClick={(e) => Qtyminus(idx.id)}
                            >
                              -
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td
                        onClick={(e) => {
                          setChangeItemId(idx.id);
                          priceModalToggle();
                        }}
                      >
                        {idx.salePrice}
                      </td>
                      <td>
                        <Link
                          className="delete-button"
                          onClick={(e) => removeItem(idx.id)}
                          to="#"
                        >
                          <i className="fa fa-trash-o pl-2"></i>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </thead>
            </Table>
          </div>
        </Row>
        <Row style={style.footer}>
          <div style={style.h5s}>
            <h5 className="mb-0">
              Quantity : <span>{Qty}</span>
            </h5>
            <h5 className="mb-0">
              Total :
              <span>
                {"\u20AC"}
                {netPrice}
              </span>
            </h5>
          </div>
          <div className="text-center" style={{ width: "100%" }}>
            <Button color="info" className="mx-2">
              Hold
            </Button>
            <Button
              color="primary"
              className="mx-2"
              onClick={(e) => {
                handleCustomerBalnce(e);
                toggle();
              }}
            >
              {"\u20AC"}Cash
            </Button>
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={openM}
        toggle={toggle}
        className="modal-view modal-lg modal-dialog-centered"
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className="success-screen">
            <div className="thank-you text-center" style={{ padding: "20px" }}>
              <h2 className="text-white mb-0">Payment</h2>
            </div>
            <div className="pt-4 px-4 px-md-5 pb-3">
              <Form onSubmit={(e) => placeOrder(e)}>
                <Row>
                  <Col md={6}>
                    {list !== null &&
                    customerBalance !== null &&
                    !posLoading ? (
                      <Container>
                        <h6>Payment</h6>
                        <ul className="list-unstyled mb-0">
                          <li style={style.listLis} className="text-dark">
                            Name: {customerBalance.customerName}
                          </li>
                          <li style={style.listLis} className="text-dark">
                            Total Items: {Qty}
                          </li>
                          <li style={style.listLis} className="text-danger">
                            Current Purchase Total: {"\u20AC"}
                            {netPrice}.00
                          </li>
                          <li style={style.listLis} className="text-dark">
                            Current Paid amount: {"\u20AC"}
                            {pay}.00
                          </li>
                          <li style={style.listLis} className="text-warning">
                            Blance: {"\u20AC"}
                            {balance}.00
                          </li>
                          <li style={style.listLis} className="text-info">
                            Change return: {"\u20AC"}
                            {changeRe}.00
                          </li>
                          <li style={style.listLis} className="text-danger">
                            Dues Remaining: {"\u20AC"}
                            {customerBalance.totalDueAmount}.00
                          </li>
                        </ul>
                      </Container>
                    ) : (
                      <div id="myloader">
                        <Loader
                          type="Puff"
                          color="#04d39f"
                          height={100}
                          width={100}
                        />
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <Container>
                      <FormGroup>
                        <h6>Amount</h6>
                        <Input
                          type="number"
                          name="amount"
                          min={1}
                          id="exampleEmail"
                          placeholder="Pay now..."
                          style={{ maxWidth: "300px" }}
                          onChange={(e) => payNow(e)}
                        />
                      </FormGroup>
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <div style={style.btns}>
                    <Button
                      color="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        toggle();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" className="ml-2">
                      Complete
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={priceModal}
        toggle={priceModalToggle}
        className="modal-view modal-lg modal-dialog-centered"
      >
        <ModalHeader toggle={priceModalToggle}></ModalHeader>
        <ModalBody>
          <div className="success-screen">
            <div className="thank-you text-center" style={{ padding: "20px" }}>
              <h2 className="text-white mb-0">Change Price</h2>
            </div>
            <div className="pt-4 px-4 px-md-5 pb-3">
              {changeItemId ? (
                <Form onSubmit={(e) => changePrice(e)}>
                  <Row>
                    <Col md={6}>
                      <Container>
                        <FormGroup>
                          <h6>Amount</h6>
                          <Input
                            type="number"
                            name="price"
                            min={1}
                            placeholder="Change rate..."
                            style={{ maxWidth: "300px" }}
                          />
                        </FormGroup>
                      </Container>
                    </Col>
                  </Row>
                  <Row>
                    <div style={style.btns}>
                      <Button
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          priceModalToggle();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" color="primary" className="ml-2">
                        Complete
                      </Button>
                    </div>
                  </Row>
                </Form>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default PosSystem;
