import * as Actions from "../actions";
import * as moment from "moment";
import { toast } from "react-toastify";

const initialState = {
  Loading: false,
  posLoading: false,
  Categories: null,
  Products: {
    rows: null,
    totalProducts: null,
    ProductDetails: null,
  },
  Customers: {
    rows: null,
    totalCustomers: null,
    customerDetails: null,
  },
  CustomerOrders: {
    rows: null,
    totalOrders: null,
  },
  Orders: {
    rows: null,
    totalOrders: null,
    orderDetails: null,
  },
  Expense: {
    rows: null,
    totalExp: null,
    expDetails: null,
  },
  Pos: [],
  CustomerBalance: null,
  splineGraph: null,
  barGraph: null,
  pieGraph: null,
  expGraph: null,
};

const appReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CATEGORIES: {
      let resFormattedMapped = [];
      if (action.payload) {
        let res = action.payload;
        resFormattedMapped = res.map((cat) => ({
          id: cat.id,
          name: cat.name,
          created_at: moment(cat.datetime).format("MMM DD YYYY h:mm A"),
        }));
      }
      return {
        ...state,
        Categories: resFormattedMapped,
      };
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        Loading: action.payload,
      };
    }
    case Actions.POS_LOADING: {
      return {
        ...state,
        posLoading: action.payload,
      };
    }
    case Actions.GET_ORDERS: {
      let resFormattedMapped = [];
      let totalOrders;
      if (action.payload) {
        let res = action.payload;
        totalOrders = res.count;
        resFormattedMapped = res.rows.map((inv) => ({
          id: inv.id,
          totalPrice: inv.totalPrice,
          paidAmount: inv.paidAmount,
          pendingBalance: inv.pendingBalance,
          status: inv.status,
          customerName: inv.orderCustomer.fullName,
          saleDate: moment(inv.createdAt).format("MMM DD YYYY h:mm A"),
        }));
      }
      return {
        ...state,
        Orders: { rows: resFormattedMapped, totalOrders },
      };
    }
    case Actions.CUSTOMER_ORDERS: {
      let resFormattedMapped = [];
      let totalOrders;
      if (action.payload) {
        let res = action.payload;
        totalOrders = res.count;
        resFormattedMapped = res.rows.map((ord) => ({
          id: ord.id,
          customerId: ord.orderCustomer.id,
          customerName: ord.orderCustomer.fullName,
          totalPrice: ord.totalPrice,
          paidAmount: ord.paidAmount,
          pendingBalance: ord.pendingBalance,
          status: ord.status,
          saleDate: moment(ord.createdAt).format("MMM DD YYYY h:mm A"),
          productsOrder: ord.productOrder.map((pro) => ({
            id: pro.productorderProduct.id,
            quantity: pro.quantity,
            rate: pro.rate,
            productName: pro.productorderProduct.name,
            image: pro.productorderProduct.image,
          })),
        }));
      }
      return {
        ...state,
        CustomerOrders: { rows: resFormattedMapped, totalOrders },
      };
    }
    case Actions.GET_ORDER_DETAIL: {
      let orderDetails;
      if (action.payload) {
        let res = action.payload;
        orderDetails = {
          id: res.id,
          totalPrice: res.totalPrice,
          paidAmount: res.paidAmount,
          pendingBalance: res.pendingBalance,
          status: res.status,
          customerName: res.orderCustomer.fullName,
          products: res.productOrder.map((idx) => ({
            id: idx.id,
            quantity: idx.quantity,
            rate: idx.rate,
            productId: idx.productorderProduct.id,
            productName: idx.productorderProduct.name,
            productSalePrice: idx.productorderProduct.salePrice,
          })),
          saleDate: moment(res.createdAt).format("MMM DD YYYY h:mm A"),
          updateDate: moment(res.updatedAt).format("MMM DD YYYY h:mm A"),
        };
      }
      return {
        ...state,
        Orders: { ...state.Orders, orderDetails },
      };
    }
    case Actions.EMPTY_PRODUCTS: {
      return {
        ...state,
        Products: {
          rows: null,
          totalProducts: null,
        },
      };
    }
    case Actions.GET_PRODUCTS: {
      let resFormattedMapped = [];
      let totalProducts;
      if (action.payload) {
        let res = action.payload;
        totalProducts = res.count;
        resFormattedMapped = res.rows.map((inv) => ({
          id: inv.id,
          name: inv.name,
          salePrice: inv.salePrice,
          stock: inv.stock,
          image: inv.image,
          createdAt: moment(inv.createdAt).format("MMM DD YYYY h:mm A"),
          category: inv.productCategory.name,
        }));
      }
      return {
        ...state,
        Products: { rows: resFormattedMapped, totalProducts },
      };
    }
    case Actions.PRODUCT_DETAILS: {
      let ProductDetails;
      if (action.payload) {
        let res = action.payload;
        ProductDetails = {
          id: res.id,
          name: res.name,
          salePrice: res.salePrice,
          buyPrice: res.buyPrice,
          stock: res.stock,
          image: res.image,
          brand: res.brand,
          SKU: res.SKU,
          details: res.details,
          createdAt: moment(res.createdAt).format("MMM DD YYYY h:mm A"),
          categoryId: res.productCategory.id,
          category: res.productCategory.name,
        };
      }
      return {
        ...state,
        Products: { ...state.Products, ProductDetails },
      };
    }
    case Actions.GET_CUSTOMERS: {
      let resFormattedMapped = [];
      let totalCustomers;
      if (action.payload) {
        let res = action.payload;
        totalCustomers = res.count;
        resFormattedMapped = res.rows.map((cust) => ({
          id: cust.id,
          fullName: cust.fullName,
          phone: cust.phone,
          address: cust.address,
          detail: cust.detail,
          createdAt: moment(cust.createdAt).format("MMM DD YYYY h:mm A"),
        }));
      }
      return {
        ...state,
        Customers: { rows: resFormattedMapped, totalCustomers },
      };
    }
    case Actions.CUSTOMER_DETAILS: {
      let customerDetails;
      if (action.payload) {
        let res = action.payload;
        customerDetails = {
          id: res.id,
          fullName: res.fullName,
          phone: res.phone,
          address: res.address,
          detail: res.detail,
          createdAt: moment(res.createdAt).format("MMM DD YYYY h:mm A"),
        };
      }
      return {
        ...state,
        Customers: { ...state.Customers, customerDetails },
      };
    }
    case Actions.CUSTOMER_BALANCE: {
      let CustomerBalance;
      if (action.payload) {
        let res = action.payload;
        CustomerBalance = res;
      }
      return {
        ...state,
        CustomerBalance,
      };
    }
    case Actions.NULL_BALANCE: {
      return {
        ...state,
        CustomerBalance: {
          customerId: 1,
          customerName: "Walkin Customer",
          totalDueAmount: 0,
        },
      };
    }
    case Actions.ADD_POS: {
      let Pos = [];
      if (action.data) {
        let res = action.data;
        let selected = state.Pos.find((prod) => prod.id === res.id);
        if (selected === null || selected === undefined) {
          Pos = {
            id: res.id,
            name: res.name,
            quantity: 1,
            stock: res.stock,
            salePrice: res.salePrice,
          };
          return {
            ...state,
            Pos: [...state.Pos, Pos],
          };
        } else {
          selected.quantity += 1;
          return {
            ...state,
            Pos: [...state.Pos],
          };
        }
      } else {
        return state;
      }
    }
    case Actions.QTY_PLUS: {
      if (action.data) {
        let res = action.data;
        let selected = state.Pos.find((prod) => prod.id === res);
        if (selected === null || selected === undefined) {
          return {
            ...state,
            Pos: [...state.Pos],
          };
        } else {
          if (selected.quantity < selected.stock) {
            selected.quantity += 1;
            return {
              ...state,
              Pos: [...state.Pos],
            };
          } else {
            toast.warn("Stock limit reached");
            return {
              ...state,
            };
          }
        }
      } else {
        return state;
      }
    }
    case Actions.QTY_MINUS: {
      if (action.data) {
        let res = action.data;
        let selected = state.Pos.find((prod) => prod.id === res);
        if (selected === null || selected === undefined) {
          return {
            ...state,
            Pos: [...state.Pos],
          };
        } else {
          if (selected.quantity > 1) {
            selected.quantity -= 1;
            return {
              ...state,
              Pos: [...state.Pos],
            };
          } else {
            return {
              ...state,
              Pos: [...state.Pos],
            };
          }
        }
      } else {
        return state;
      }
    }
    case Actions.CHANGE_QUANTITY: {
      if (action.payload) {
        let res = action.payload;
        let selected = state.Pos.find((prod) => prod.id === res.id);
        if (selected === null || selected === undefined) {
          return {
            ...state,
          };
        } else if (res.quantity > selected.stock) {
          toast.warn("Stock limit reached");
          return {
            ...state,
          };
        } else {
          selected.quantity = JSON.parse(res.quantity);
          return {
            ...state,
            Pos: [...state.Pos],
          };
        }
      } else {
        return state;
      }
    }
    case Actions.CHANGE_PRICE: {
      if (action.payload) {
        let res = action.payload;
        let selected = state.Pos.find((prod) => prod.id === res.id);
        if (selected === null || selected === undefined) {
          return {
            ...state,
            Pos: [...state.Pos],
          };
        } else {
          selected.salePrice = res.salePrice;
          return {
            ...state,
            Pos: [...state.Pos],
          };
        }
      } else {
        return state;
      }
    }
    case Actions.REMOVE_ITEM: {
      if (action.data) {
        let res = action.data;
        let selected = state.Pos.filter((prod) => prod.id !== res);
        if (selected === null || selected === undefined) {
          return {
            ...state,
            Pos: [...state.Pos],
          };
        } else {
          return {
            ...state,
            Pos: [...selected],
          };
        }
      } else {
        return state;
      }
    }
    case Actions.EMPTY_POS: {
      return {
        ...state,
        Pos: [],
      };
    }
    case Actions.GET_EXPENSE: {
      let resFormattedMapped = [];
      let totalExp;
      if (action.payload) {
        let res = action.payload;
        totalExp = res.count;
        resFormattedMapped = res.rows.map((exp) => ({
          id: exp.id,
          expenseDate: moment(exp.expenseDate).format("MMM DD YYYY"),
          category: exp.category,
          expenseFor: exp.expenseFor,
          amount: exp.amount,
          created_at: moment(exp.createdAt).format("MMM DD YYYY h:mm A"),
        }));
      }
      return {
        ...state,
        Expense: { rows: resFormattedMapped, totalExp },
      };
    }
    case Actions.GET_EXPENSEDETAILS: {
      let expDetails = {};
      if (action.payload) {
        let res = action.payload;
        expDetails = {
          expenseDate: moment(res.expenseDate).format("YYYY-MM-DD"),
          category: res.category,
          expenseFor: res.expenseFor,
          amount: res.amount,
        };
      }
      return {
        ...state,
        Expense: { ...state.Expense, expDetails },
      };
    }
    case Actions.GET_DMGGOODS: {
      let resFormattedMapped = [];
      let totalExp;
      if (action.payload) {
        let res = action.payload;
        totalExp = res.count;
        resFormattedMapped = res.rows.map((exp) => ({
          id: exp.id,
          amount: exp.amount,
          productName: exp.productDamageGoods.name,
          created_at: moment(exp.datetime).format("MMM DD YYYY h:mm A"),
        }));
      }
      return {
        ...state,
        Expense: { rows: resFormattedMapped, totalExp },
      };
    }
    case Actions.GET_SPLINEGRAPH: {
      let splineGraph = { day: [], week: [], month: [] };
      if (action.payload) {
        let res = action.payload;
        splineGraph.day = res[0].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM, DD")),
          y: exp.totalCount,
        }));
        splineGraph.week = res[1].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM, DD")),
          y: exp.totalCount,
        }));
        splineGraph.month = res[2].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM")),
          y: exp.totalCount,
        }));
      }
      return {
        ...state,
        splineGraph,
      };
    }
    case Actions.GET_BARGRAPH: {
      let barGraph = { day: [], week: [], month: [] };
      if (action.payload) {
        let res = action.payload;
        barGraph.day = res[0].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM, DD")),
          y: JSON.parse(exp.salesAmount),
        }));
        barGraph.week = res[1].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM, DD")),
          y: JSON.parse(exp.salesAmount),
        }));
        barGraph.month = res[2].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM")),
          y: JSON.parse(exp.salesAmount),
        }));
      }
      return {
        ...state,
        barGraph,
      };
    }
    case Actions.GET_EXPGRAPH: {
      let expGraph = { day: [], week: [], month: [] };
      if (action.payload) {
        let res = action.payload;
        expGraph.day = res[0].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM, DD")),
          y: JSON.parse(exp.totalExpense),
        }));
        expGraph.week = res[1].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM, DD")),
          y: JSON.parse(exp.totalExpense),
        }));
        expGraph.month = res[2].map((exp) => ({
          x: new Date(moment(exp.createdAt).format("YYYY, MM")),
          y: JSON.parse(exp.totalExpense),
        }));
      }
      return {
        ...state,
        expGraph,
      };
    }
    case Actions.GET_PIEGRAPH: {
      let pieGraph = { graph: [], netProfit: null };
      if (action.payload) {
        let res = action.payload;
        pieGraph.graph.push({
          indexLabel: "Sales",
          y: JSON.parse(res[0][0].salesAmount),
        });
        pieGraph.graph.push({
          indexLabel: "Expense",
          y: JSON.parse(res[1][0].totalExpense),
        });
        pieGraph.graph.push({
          indexLabel: "Purchase",
          y: JSON.parse(res[2][0].totalPurchase),
        });
        pieGraph.netProfit =
          JSON.parse(res[0][0].salesAmount) -
          (JSON.parse(res[2][0].totalPurchase) +
            JSON.parse(res[1][0].totalExpense));
      }
      return {
        ...state,
        pieGraph,
      };
    }
    case Actions.GET_ORDERAGGR: {
      let orderAggr;
      if (action.payload) {
        orderAggr = action.payload[0];
      }
      return {
        ...state,
        orderAggr,
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
