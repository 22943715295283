/**
 *  Admin Site Product Listing Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container, Button } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import AdminproductList from './AdminProduct';
import * as actions from 'store/actions'
import Loader from 'react-loader-spinner';
const style= {
    btna: {
        marginLeft: '10px'
    }
}
function AdminProduct() {
    const dispatch = useDispatch()
    const [productList, setProductList] = useState(null)
    const [productCount, setProductCount] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const productData = useSelector(({app}) => {
        return app ? app.Products : false
    })
    const loading = useSelector(({app}) => app.Loading)
    useEffect(() => {
        if(Auth){
            dispatch(actions.setLoading(true))
            dispatch(actions.getProducts(currentPage))
        }
    }, [Auth,currentPage,dispatch])
    useEffect(() => {
        if(productData){
            setProductList(productData.rows)
            setProductCount(productData.totalProducts)
        }
    },[productData])
    const onProductSearch = (e) => {
        e.preventDefault()
        let SearchValue = e.target.srchVal.value;
        dispatch(actions.SearchProducts(1,SearchValue, null))
    }

    const onPageChanged = e => {
        let pg = e.selected +1
        setCurrentPage(pg)
        };


    const onDeleteProduct = (id) => {
        if(id){
            dispatch(actions.deleteProduct(id))
        }
    }
        return (
        <div className="section-ptb">
            <Container className="themed-container" fluid="md">
                <Row>
                    <Col lg={12}>
                        <div className="mb-0">
                            <h4>Product List</h4>
                        </div>
                        <div className="mb-4">
                            <form onSubmit= {e => onProductSearch(e)}>
                                <div className="form-group mb-0">
                                    <input type="search" className="form-control" placeholder="Search product" name='srchVal' style={{width: '65%', display: 'inline-block'}} />
                                    <Button type='submit' color='primary'style={style.btna}>Search</Button>
                                </div>
                            </form>
                        </div>
                        <div className="mb-0 mb-md-4">
                        {productList===null || loading === true ?
                        <div id="preloader">
                        <Loader type="Puff" color="#04d39f" height={100} width={100} />
                        </div> : 
                        productList.length > 0 ?
                            <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                {productList.map((product, index) =>
                                    <AdminproductList product={product} key={index} deleteproduct={()=>onDeleteProduct(product.id)} />
                                    )
                                }
                            </Row>
                            :
                                <Row className="products products-loop grid ciyashop-products-shortcode">
                                    <div className="col-sm-12 text-center  mt-4 mt-md-5" >
                                        <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" alt='empty-search' />
                                        <h3>Sorry! No products were found matching your selection!    </h3>
                                        <p>Please try to other words.</p>
                                    </div>
                                </Row>
                            }
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {(productCount && productCount >12) && loading === false ?
                    <ReactPaginate
                        pageCount = {productCount/12}
                        initialPage = {currentPage-1}
                        onPageChange = {e => onPageChanged(e)}
                        activeClassName={'active'}
                        containerClassName={'pagination332'}
                        subContainerClassName={'pages pagination'}
                        breakClassName={'break-me'}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        breakLabel={'...'}
                    />
                    : <></>}
                    </Col>
                </Row>

            </Container>
        </div>
        )
}
export default AdminProduct;
