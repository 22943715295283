import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input,Container } from 'reactstrap';
import history from '@history'
import * as Auth from 'auth/store/actions'


function LoginPage() {
    const dispatach = useDispatch();
    const [data, setData] = useState(null);
    const auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        if(auth){
            history.push({
                pathname: '/dashboard'
            })
        }
    },[auth])
    const style = {
        wrapper : {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column', 
            justifyContent: 'center',
            width: '100%',
            minHeight: '100%',
            padding: '20px',
        },
        form : {
            borderRadius: '10px 10px 10px 10px',
            background: 'rgb(220, 225, 232)',
            padding: '30px',
            width: '100%',
            maxWidth: '450px',
            position: 'relative',
            boxShadow: '0 30px 60px 0 rgba(0,0,0,0.1)',
        },
        button : {
            backgroundColor: '#56baed',
            border: 'none',
            color: 'white',
            padding: '15px 80px',
            textAlign: 'center',
        }
    }
    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name] : e.target.value
        })
    }
    const onFormSubmit= (ev) => {
        ev.preventDefault()
        dispatach(Auth.submitLogin(data))
    }
    return(
        <div>
            <Container style={{height: '90vh'}}>
                <div style={style.wrapper}>
                <Form style={style.form} onSubmit={ev => onFormSubmit(ev)}>
                    <h2 className="text-center">Admin Login</h2>
                    <FormGroup style={{margin: '30px 0px'}}>
                        <Input type="text" name="username" placeholder="Username..." onChange={e => onChange(e)}/>
                    </FormGroup>
                    <FormGroup style={{margin: '20px 0px'}}>
                        <Input type="password" name="password" placeholder="password..."  onChange={e => onChange(e)}/>
                    </FormGroup>
                    <div style ={{textAlign: 'center', marginTop: '10px'}}>
                    <Button type='submit' style={style.button}>LOG IN</Button>
                    </div>
                </Form>
                </div>
            </Container>
        </div>
    )
}
export default LoginPage;