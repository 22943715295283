import Domain from 'config.js'
import axios from 'axios';
export const GET_SPLINEGRAPH = '[APP] GET_SPLINEGRAPH';
export const GET_BARGRAPH = '[APP] GET_BARGRAPH';
export const GET_PIEGRAPH = '[APP] GET_PIEGRAPH';
export const GET_ORDERAGGR = '[APP] GET_ORDERAGGR';

export function splineGraph() {
    const request = axios.get(`${Domain}/splineGraph`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_SPLINEGRAPH,
                payload: response.data.result
            })
        }
        )
    )
}
export function barGraph() {
    const request = axios.get(`${Domain}/barGraph`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_BARGRAPH,
                payload: response.data.result
            })
        }
        )
    )
}
export function pieGraph() {
    const request = axios.get(`${Domain}/pieGraph`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PIEGRAPH,
                payload: response.data.result
            })
        }
        )
    )
}
export function orderAggr() {
    const request = axios.get(`${Domain}/orderAggr`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ORDERAGGR,
                payload: response.data.result
            })
        }
        )
    )
}
