import {combineReducers} from 'redux';
import auth from '../../auth/store/reducers';
import app from './app.reducer';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        app,
        ...asyncReducers
    });

export default createReducer;