import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Input, Button, Container} from 'reactstrap';
import Loader from 'react-loader-spinner';
import Domain from 'config.js';
import * as Actions from 'store/actions'

const style = {
    inStyle : {
        position : 'relative',
    },
    bStyle : {
        position : 'absolute',
        padding : '8px 10px',
        top : '5px',
        right : '20px'

    },
    proMain : {
        position : 'relative',
        minHeight : '500px',
        maxHeight : '500px',
        overflowY : 'auto',
        overflowX : 'hidden',
        padding: '10px 0px',
        width : '100%',
    }
}
function PosItems(props){
    const dispatch = useDispatch()
    const [Cat, setCat] = useState(null);
    const [catId, setCatId] = useState(null);
    const [srchVal, setSrchVal] = useState({SearchValue : ''});
    const Category = useSelector(({app}) => app.Categories)
    const loading = useSelector(({app}) => app.Loading)
     useEffect(() => {
        setCat(Category)
    },[Category])

    const CatChange = (e) => {
        let catchng = e.target.value;
        if(catchng === 'All'){
            setCatId(null)
            dispatch(Actions.SearchProducts(1,srchVal.SearchValue,null))
        } else {
            setCatId(catchng)
            dispatch(Actions.SearchProducts(1,srchVal.SearchValue,catchng))
        }
    }
    const searchSubmit = (e) => {
        e.preventDefault();
        dispatch(Actions.SearchProducts(1,srchVal.SearchValue,catId))
    }
    const AddPos = (Pro) => {
        dispatch(Actions.AddPos(Pro))
    }
    const srchChange = e => {
        setSrchVal({
            [e.target.name] : e.target.value
        })
    }
    return(
        <Container style= {{border : '1px solid #e5e5e5'}}>
            <Row style ={{marginTop : '10px'}}>
                <Col>
                    <select name = 'category' className="form-control"  onChange= {e=> CatChange(e)} required>
                        <option value='All'>All Category</option>
                        {Cat && Cat!==null ?
                        Cat.map(cat => (
                        <option value={cat.id} key={cat.id}>{cat.name}</option>
                        )) : <option value="cat.id">wait</option>}
                    </select>
                </Col>
                <Col>
                <form onSubmit={e => searchSubmit(e)}>
                    <Input name= 'SearchValue' type='text' style= {style.inStyle} value= {srchVal.SearchValue} onChange={e => srchChange(e)}/>
                    <Button color="primary" size= 'sm' type='submit'  style= {style.bStyle}>Find</Button>
                </form>
                </Col>
            </Row>
            <div>
                <div style={style.proMain}>
                    <Row>
                        {props.products === null || loading === true ?
                        <div id ='myloader'>
                            <Loader type="Puff" color="#04d39f" height={100} width={100} />
                        </div>:
                        props.products.map(Pro => (
                            <Col xs='6' sm='3' md='3' lg='3' key={Pro.id}>
                                <div className="product-thumbnail-inner">
                                    {Pro.image ?
                                        <div className="product-thumbnail-main" style ={{cursor : 'pointer'}} onClick ={e => AddPos(Pro)}>
                                            <img src={`${Domain}${Pro.image}`} className="img-fluid" alt="productImage"style={{borderRadius: '12px'}}/>
                                            <div className='text-center'>
                                            <span style={{fontSize: '12px'}}>{Pro.name}</span>
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            </Col>
                        ))
                        }
                    
                    </Row>
                </div>
            </div>
        </Container>
    )
}
export default PosItems;