import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'store/actions'
import Loader from 'react-loader-spinner';
import { Row, FormGroup, Input, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

function EditExpense(props){
    const dispatch = useDispatch()
    const expId = parseInt(props.match.params.id);
    const [expense, setExpense] = useState(null)
    const expData = useSelector(({app}) => app.Expense.expDetails)
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(Actions.expenseDetails(expId))
    },[dispatch,expId])
    useEffect(() => {
        setExpense(expData)
    },[expData])
    const formChange = (e) => {
        setExpense({
            ...expense,
            [e.target.name] : e.target.value
        })
    }
    const formSubmit = (e) => {
        e.preventDefault()
        expense.id = expId
        dispatch(Actions.updateExpense(expense))
    }
    if(expense === null || expense === undefined){
        return (
            <div id="preloader">
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
            )
          }
    return(
        <div className="site-content">
            <div className="content-wrapper section-ptb">
                <Container>
                    <h2>Edit Expense</h2>
                <Row>
                    <div className="product-top-right col-xl-7 col-md-6">
                        <div className="product-top-right-inner">
                            <form onSubmit={e => formSubmit(e)}>
                                    <FormGroup className="edit-icon">
                                        <Input type="date" name = 'expenseDate' onChange= {e=> formChange(e)} value= {expense.expenseDate} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'category' placeholder="category" onChange= {e=> formChange(e)} value= {expense.category} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'expenseFor' placeholder="expenseFor" onChange= {e=> formChange(e)} value= {expense.expenseFor} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input  type="number" min={1} name = 'amount' placeholder="amount" onChange= {e=> formChange(e)}  value= {expense.amount} required/>
                                    </FormGroup>
                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                    <Link to="/customers" className="btn btn-danger mb-2"> Cancel </Link>
                            </form>
                        </div>
                    </div>
                </Row>
                </Container>
            </div>
        </div>
    )
}
export default EditExpense;