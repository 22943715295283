import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '@history'
export const PLACR_ORDERS = '[APP] PLACR_ORDERS';
export const GET_ORDERS = '[APP] GET_ORDERS';
export const GET_ORDER_DETAIL = '[APP] GET_ORDER_DETAIL';
export const UPDATE_ORDER = '[APP] UPDATE_ORDER';
export const EMPTY_POS = '[APP] EMPTY_POS';
export const CLEAR_PENDING_DUES = '[APP] CLEAR_PENDING_DUES';



export function placeOrder(data) {
    const request = axios.post(`${Domain}/placeOrder`,data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: PLACR_ORDERS,
            })
        }
        ).then(() => toast.success('Order placed successfuly'))
        .then(() => history.push({pathname: '/sales'}))
        .then(() => dispatch(emptyPos()))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot place order')
        })
    )
}
export function clearPendingDues(data) {
    const request = axios.post(`${Domain}/placeOrder`,data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: CLEAR_PENDING_DUES,
            })
        }
        ).then(() => toast.success('Payment recieved'))
        .catch(error => {
            console.log(error);
            toast.warn('Error in payment')
        })
    )
}
export function emptyPos(){
    return {
        type : EMPTY_POS,
    }
}
export function getOrders(page, status, createdAt) {
    const request = axios.post(`${Domain}/getOrders`, {page, status, createdAt});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ORDERS,
                payload: response.data.result
            })
        }
        )
    )
}
export function getOrderDetails(id) {
    const request = axios.get(`${Domain}/getOrderDetail`, {
        params: { id }
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ORDER_DETAIL,
                payload: response.data.result
            })
        }
        )
    )
}
export function updateOrder(id, amount) {
    const request = axios.put(`${Domain}/updateOrder`, {id, amount});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: UPDATE_ORDER,
            })
        }
        )
        .then(() => toast.success("Updated Successfully"))
        .then(() => dispatch(getOrders(1)))
        .catch(error => {
            console.log(error)
            toast.warn('Cannot update')
        })
    )
}
