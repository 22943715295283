/*
* Admin Site Product Edit Page
*/
import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, FormGroup, Label, Input } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import * as Actions from 'store/actions'
import history from '@history'
import Domain from 'config.js'
import 'font-awesome/css/font-awesome.min.css';


function ProductEditDetail(props) {
    const dispatch = useDispatch()
    const user = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    var values = {
        name: props.product.name,
        salePrice: props.product.salePrice,
        buyPrice: props.product.buyPrice,
        stock: props.product.stock,
        brand: props.product.brand,
        SKU: props.product.SKU,
        details: props.product.details,
        categoryId: props.product.categoryId,
    }
    const Cat = useSelector(({app}) => app.Categories)
    const [newimg, setNewimg] = useState(null)
    const [formEdit, setFormEdit] = useState(values)
      if(user === false ){
          history.push({
              pathname: '/'
          })
      }
      const onChangeForm = (e) => {
        setFormEdit({
            ...formEdit,
            [e.target.name] : e.target.value
        })
      }
      const formSubmit = (e) => {
        e.preventDefault()
        if(newimg !== null){
            const fd = new FormData();
            fd.append('name', formEdit.name);
            fd.append('salePrice', formEdit.salePrice);
            fd.append('buyPrice', formEdit.buyPrice);
            fd.append('stock', formEdit.stock);
            fd.append('brand', formEdit.brand);
            fd.append('SKU', formEdit.SKU);
            fd.append('details', formEdit.details);
            fd.append('category_id', formEdit.categoryId);
            fd.append('image', newimg[0])
            dispatch(Actions.updateProduct(fd,props.product.id))
        } else {
            console.log('no formdata')
            const edit = {
                name : formEdit.name,
                salePrice : formEdit.salePrice,
                buyPrice : formEdit.buyPrice,
                stock : formEdit.stock,
                brand : formEdit.brand,
                SKU: formEdit.SKU,
                details : formEdit.details,
                category_id : formEdit.categoryId
            }
            dispatch(Actions.updateProduct(edit,props.product.id))
        }
    }
    const ImageChange = (e) => {
        setNewimg(e)
    }
    const {product} = props;
        return (
            <section>
                <div className="product-content-top single-product single-product-edit">
                <Row>
                    <div className="product-top-left col-xl-5 col-md-6">
                        <div className="product-top-left-inner">
                            <div className="ciyashop-product-images">
                            <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                <div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                    <div className="ciyashop-product-gallery__image">
                                        <img src={`${Domain}${product.image}`} className="img-fluid" alt ="main"/>
                                        <ImageUploader
                                            buttonText=""
                                            onChange={ImageChange}
                                            withPreview
                                            withIcon={false}
                                            maxFileSize={5242880}
                                            imgExtension={['.jpg', 'jpeg','.gif', '.png', '.gif']}
                                        />
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-top-right col-xl-7 col-md-6">
                    <div className="product-top-right-inner">
                        <form className="summary entry-summary" onSubmit={formSubmit}>
                            <FormGroup className="edit-icon">
                                <Input type="text" className="form-control product_title" name='name' placeholder="Product Name" defaultValue={product.name} onChange={e => onChangeForm(e)} />
                            </FormGroup>
                            <FormGroup className="edit-icon">
                                <Label className="title pl-0">Sale Price</Label>
                                <Input type="text" className="form-control price" name='salePrice' placeholder="Product Sale Price" defaultValue={product.salePrice} onChange={e => onChangeForm(e)} />
                            </FormGroup>
                            <FormGroup className="edit-icon">
                                <Label className="title pl-0">Buy Price</Label>
                                <Input type="text" className="form-control price" name='buyPrice' placeholder="Product Buy Price" defaultValue={product.buyPrice} onChange={e => onChangeForm(e)} />
                            </FormGroup>
                            <FormGroup className="edit-icon">
                                <Label className="title pl-0">Product Brand</Label>
                                <Input type="text" className="form-control product_title" name='brand' placeholder="Product Brand" defaultValue={product.brand} onChange={e => onChangeForm(e)} />
                            </FormGroup>
                            <FormGroup className="edit-icon">
                                <Input  type="textarea" className="form-control" rows="3"name='details' placeholder="Product Description" defaultValue={product.details} onChange={e => onChangeForm(e)} />
                            </FormGroup>
                            <Label className="title">Category</Label>
                            <select name="categoryId" id="category" className="form-control" onChange={e => onChangeForm(e)} >
                                <option selected="selected" value = {product.categoryId}>{product.category}</option>
                                {Cat && Cat !==null ?
                                Cat.map(cat => (
                                        <option key = {cat.id} value = {cat.id}>{cat.name}</option>
                                )) : <option value ='0'>wait</option>}
                            </select>

                            <Label className="title">SKU</Label>
                            <input type="text" className="form-control" name='SKU' placeholder="Product SKU" defaultValue={product.SKU} onChange={e => onChangeForm(e)}></input>
                            <Label className="title">Product Stock</Label>
                            <input type="text" className="form-control" name='stock' placeholder="Product Stock" defaultValue={product.stock} onChange={e => onChangeForm(e)}></input>

                            <button type='submit' href="#" className="btn btn-primary mb-2 mr-2"> Update </button>
                            <Link to="/products" className="btn btn-danger mb-2"> Cancel </Link>
                        </form>
                    </div>
                    </div>
                </Row>
                </div>
            </section>

        )
}
export default ProductEditDetail;

