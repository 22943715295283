import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions";
import {
  Row,
  Col,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import Loader from "react-loader-spinner";
import history from "@history";

function Customers() {
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState(null);
  const [custCount, setCustCount] = useState(null);
  const [page, setPage] = useState(1);
  const Cust = useSelector(({ app }) => app.Customers);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    if (Auth) {
      dispatch(Actions.getCustomers(page));
    }
  }, [dispatch, page, Auth]);
  useEffect(() => {
    setCustomers(Cust.rows);
    setCustCount(Cust.totalCustomers);
  }, [Cust]);
  const paginate = (e) => {
    let pg = e.selected + 1;
    setPage(pg);
  };
  if (Auth === false) {
    history.push({
      pathname: "/",
    });
  }
  if (customers === null) {
    return (
      <div id="preloader">
        <Loader type="Puff" color="#04d39f" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="section-ptb">
      <Container className="themed-container" fluid="md">
        <Row>
          <Col lg={12}>
            <div className="mb-0">
              <h4>Customers List</h4>
            </div>
            <div style={{ overflowX: "auto" }}>
              <Table striped style={{ minWidth: "800px" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Details</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {customers.length === 0 ? (
                  <tbody>
                    <tr>
                      <th colSpan={7} className="text-center">
                        No Customers Found!
                      </th>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {customers.map((cust) => (
                      <tr key={cust.id}>
                        <td>{cust.id}</td>
                        <td>{cust.fullName}</td>
                        <td>{cust.phone}</td>
                        <td>{cust.address}</td>
                        <td>{cust.detail}</td>
                        <td>{cust.createdAt}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle caret size="sm" color="primary">
                              Action
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                className="nav-link"
                                onClick={(e) => {
                                  history.push({
                                    pathname: `Customer-edit/${cust.id}`,
                                  });
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                className="nav-link"
                                onClick={(e) => {
                                  history.push({
                                    pathname: `Customer-orders/${cust.id}`,
                                  });
                                }}
                              >
                                Orders
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {custCount && custCount > 10 ? (
              <ReactPaginate
                pageCount={custCount / 10}
                initialPage={0}
                onPageChange={(e) => paginate(e)}
                activeClassName={"active"}
                containerClassName={"pagination332"}
                subContainerClassName={"pages pagination"}
                breakClassName={"break-me"}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                breakLabel={"..."}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Customers;
