/**
 *  Admin Header
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container,Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Navbar,NavbarToggler, Nav, NavItem, UncontrolledDropdown, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Actions from 'store/actions'
import * as AuthActions from 'auth/store/actions'
import history from '@history';

function AdminHeader(props) {
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(null)
    const [isOpen, setIsOpen] = useState(null)
    const [Profile, setProfile] = useState(null)
    const userData = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        dispatch(Actions.getCategories())
    },[dispatch])
    useEffect(() => {
        if(userData){
            window.scrollTo(0, 0)
            setProfile(userData)
        }
    }, [userData])

    if(userData === false){
        history.push({pathname: '/'})
    }
    const toggle2 = () => {
        setDropdownOpen(!dropdownOpen)
    }
    const toggle3 = () => {
        setIsOpen(!isOpen)
    }
    const Changeclass = (val) => {
        var removeelems = document.getElementsByClassName("nav-item");
        [].forEach.call(removeelems, function(el) {
            el.classList.remove('active');
        });

        if(val === "report")
        {
            document.querySelector(".report").classList.add("active");
        }
        if(val === "invoice")
        {
            document.querySelector(".invoice").classList.add("active");
        }
        if(val === "pos")
        {
            document.querySelector(".pos").classList.add("active");
        }
    }
    const logoutUser = () => {
        dispatch(AuthActions.logoutUser())
    }
return (
        <div className="admin-menu">
        <Container className="themed-container" fluid="md">
            <Row className="align-items-center">
            <Col md={12}>
            <div className="d-flex align-items-center positive-reletive">
            {/* <Link to="/"><h3>POS</h3></Link> */}
            {Profile && Profile !== null ?
            <Dropdown isOpen={dropdownOpen} toggle={toggle2} className="profile-dropdown ml-auto">
                <DropdownToggle caret className="btn-white">
                <div className="d-none d-sm-block">
                <h6 className="mb-0">{Profile.fullName}</h6>
                <span className="text-dark">{Profile.username}</span>
                </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={logoutUser} className="nav-link" tag={Link} to="#"><i className="fa fa-sign-out"></i>Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown>:
            <></>
            }
            
            </div>
            <Navbar light expand="md"  className="bg-white">
                <NavbarToggler onClick={toggle3} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                    <NavItem className="active report">
                        <Link  to="/dashboard" className="nav-link" onClick={() => Changeclass('report')}><i className="fa fa-line-chart"></i>Reports</Link>
                    </NavItem>
                    <NavItem className="invoice">
                        <Link to="/sales" className="nav-link" onClick={() => Changeclass('invoice')}><i className="fa fa-inbox"></i>Sales</Link>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-user-circle-o"></i>Customers
                        </DropdownToggle>
                        <DropdownMenu left ='true'>
                            <DropdownItem onClick={() => Changeclass('customers')} className="nav-link"   tag={Link} to="/customers"><i className="fa fa-user-circle-o"></i>Customers</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('customers')} className="nav-link"   tag={Link} to="/add-customers"><i className="fa fa-plus-circle"></i>Add Customer</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-credit-card"></i>Expense
                        </DropdownToggle>
                        <DropdownMenu left ='true'>
                            <DropdownItem onClick={() => Changeclass('customers')} className="nav-link"   tag={Link} to="/expense"><i className="fa fa-credit-card"></i>Expense</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('customers')} className="nav-link"   tag={Link} to="/add-expense"><i className="fa fa-plus-circle"></i>Add Expense</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-shopping-cart"></i>Products
                        </DropdownToggle>
                        <DropdownMenu left ='true'>
                            <DropdownItem onClick={() => Changeclass('product')} className="nav-link"   tag={Link} to="/products"><i className="fa fa-cart-plus"></i>Products</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('product')} className="nav-link"   tag={Link} to="/add-product"><i className="fa fa-plus-circle"></i>Add Product</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('product')} className="nav-link"   tag={Link} to="/add-category"><i className="fa fa-plus-circle"></i>Add Category</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('customers')} className="nav-link"   tag={Link} to="/dmggoods"><i className="fa fa-gavel"></i>Damage Goods</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem className="pos">
                        <Link to="/pos" className="nav-link" onClick={() => Changeclass('pos')}><i className="fa fa-money"></i>POS</Link>
                    </NavItem>
                    </Nav>
                </Collapse>
                </Navbar>
                </Col>
            </Row>
        </Container>
        </div>
        )
}
export default AdminHeader;
