import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Loader from "react-loader-spinner";
import ReactToPrint from "react-to-print";
import * as Actions from "store/actions/";

function InvoiceDetail(props) {
  const dispatch = useDispatch();
  const invoiceId = parseInt(props.invoiceId);

  const [invoice, setInvoice] = useState(null);
  const invData = useSelector(({ app }) => app.Orders.orderDetails);
  useEffect(() => {
    dispatch(Actions.getOrderDetails(invoiceId));
  }, [dispatch, invoiceId]);
  useEffect(() => {
    if (invData) {
      setInvoice(invData);
    }
  }, [invData]);
  if (invoice === null) {
    return (
      <div id="preloader">
        <Loader type="Puff" color="#04d39f" height={100} width={100} />
      </div>
    );
  }
  return (
    <>
      <Container className="themed-container" fluid="md">
        <div className="success-screen" style={{ margin: "50px 0" }}>
          <div className="thank-you text-center">
            <h2 className="text-white" style={{ margin: "0" }}>
              Order Details
            </h2>
          </div>
          <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
            <Row>
              <Col lg={6}>
                <h6>Customer</h6>
                <ul className="list-unstyled mb-0">
                  <li>{invoice.customerName}</li>
                </ul>
              </Col>
              <Col lg={6} className="text-lg-right mt-4 mt-lg-0">
                <h6>Summary</h6>
                <ul className="list-unstyled mb-0">
                  <li>
                    <span>Order ID:</span> <strong>{invoice.id}</strong>
                  </li>
                  <li>
                    <span>Order Date:</span> <strong>{invoice.saleDate}</strong>
                  </li>
                  <li>
                    <span>Order Total:</span>{" "}
                    <strong>
                      {"\u20AC"}
                      {invoice.totalPrice}
                    </strong>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className="ordered-detail">
            <h5 className="mb-4">Product Details</h5>
            <div className="table-responsive">
              <table className="table mb-0" style={{ minWidth: "400px" }}>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Net Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice && invoice !== null ? (
                    invoice.products.map((inv, idx) => (
                      <tr className="ordered-item" key={inv.id}>
                        <td className="ordered-name">
                          <span>{idx + 1}</span>
                        </td>
                        <td className="ordered-name">
                          <span>{inv.productName}</span>
                        </td>
                        <td className="ordered-quantity">
                          <span>{inv.quantity}</span>
                        </td>
                        <td className="ordered-price">
                          <span>
                            {"\u20AC"}
                            {inv.rate}
                          </span>
                        </td>
                        <td className="ordered-price">
                          <span>
                            {"\u20AC"}
                            {inv.rate * inv.quantity}.00
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>null</tr>
                  )}
                  <tr>
                    <td colSpan="3"></td>
                    <td className="text-center">
                      <strong>Total</strong>
                    </td>
                    <td className="text-center">
                      <strong>
                        {"\u20AC"}
                        {invoice.totalPrice}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Row>
              <Col md={12} lg={6}>
                <h5 className="mt-4 mb-0">Payment Information</h5>
                <div className="table-responsive">
                  <table
                    className="table total-table table-borderless mt-4 mb-0"
                    style={{ minWidth: "340px" }}
                  >
                    <tbody>
                      <tr>
                        <td>Pending Amount</td>
                        <td className="text-right">
                          {"\u20AC"}
                          {JSON.parse(invoice.pendingBalance) +
                            JSON.parse(invoice.paidAmount)}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Pending Amount</td>
                        <td className="text-right">
                          {"\u20AC"}
                          {invoice.totalPrice}
                        </td>
                      </tr>
                      <tr>
                        <td>Current paid Amount</td>
                        <td className="text-right">
                          {"\u20AC"}
                          {invoice.paidAmount}
                        </td>
                      </tr>
                      <tr className="border-top">
                        <td>
                          <strong className="h5">Dues Remaining</strong>
                        </td>
                        <td className="text-right h5">
                          <strong>
                            {"\u20AC"}
                            {invoice.pendingBalance}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}
const InvoiceDetailCon = (props) => {
  const componentRef = useRef();
  const invoiceId = parseInt(props.match.params.id);
  return (
    <div>
      <ComponentToPrint ref={componentRef} invoiceId={invoiceId} />
      <div className="text-center pb-4">
        <ReactToPrint
          trigger={() => <button>Print this out!</button>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};
class ComponentToPrint extends React.Component {
  render() {
    return <InvoiceDetail invoiceId={this.props.invoiceId} />;
  }
}
export default InvoiceDetailCon;
