import Domain from "config.js";
import axios from "axios";
export const ADD_POS = "[POS] ADD_POS";
export const QTY_PLUS = "[POS] QTY_PLUS";
export const QTY_MINUS = "[POS] QTY_MINUS";
export const REMOVE_ITEM = "[POS] REMOVE_ITEM";
export const CHANGE_PRICE = "[POS] CHANGE_PRICE";
export const CUSTOMER_BALANCE = "[POS] CUSTOMER_BALANCE";
export const NULL_BALANCE = "[POS] NULL_BALANCE";
export const POS_LOADING = "[POS] POS_LOADING";
export const CHANGE_QUANTITY = "[POS] CHANGE_QUANTITY";

export function setPosLoading(data) {
  return {
    type: POS_LOADING,
    payload: data,
  };
}
export function AddPos(data) {
  return {
    type: ADD_POS,
    data,
  };
}

export function QtyPlus(id) {
  return {
    type: QTY_PLUS,
    data: id,
  };
}
export function QtyMinus(id) {
  return {
    type: QTY_MINUS,
    data: id,
  };
}
export function removeItem(id) {
  return {
    type: REMOVE_ITEM,
    data: id,
  };
}
export function changePrice(data) {
  return {
    type: CHANGE_PRICE,
    payload: data,
  };
}
export function changeQuantity(data) {
  return {
    type: CHANGE_QUANTITY,
    payload: data,
  };
}

export function bringBalace(id) {
  const request = axios.get(`${Domain}/bringBalace`, {
    params: { id },
  });

  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: CUSTOMER_BALANCE,
          payload: response.data.result,
        });
      })
      .then(() => dispatch(setPosLoading(false)))
      .catch((error) => console.log(error));
}

export function bringNullBalnce() {
  return {
    type: NULL_BALANCE,
  };
}
