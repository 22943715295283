import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import { useState } from "react";
import {
  Container,
  Spinner,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Loader from "react-loader-spinner";
import "./orders.css";
import { toast } from "react-toastify";

function CustomerOrders(props) {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState(null);
  const [orderCount, setOrderCount] = useState(null);
  const [modal2, setModal2] = useState(false);
  const orderdata = useSelector(({ app }) => app.CustomerOrders);
  const loading = useSelector(({ app }) => app.Loading);
  const custId = parseInt(props.match.params.id);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(Actions.setLoading(true));
    dispatch(Actions.getCustomerOrders(custId));
  }, [custId, dispatch]);

  useEffect(() => {
    setOrders(orderdata.rows);
    setOrderCount(orderdata.totalOrders);
  }, [orderdata, dispatch]);

  let totalAmount = null;
  let totalPaidAmount = null;
  if (orders && orders.length > 0) {
    orders.map((idx) => {
      totalAmount += JSON.parse(idx.totalPrice);
      totalPaidAmount += JSON.parse(idx.paidAmount);
      return null;
    });
  }
  const toggle2 = () => {
    setModal2(!modal2);
  };

  const payDebt = (e) => {
    e.preventDefault();
    let amountDue = e.target.amount.value;
    if (amountDue <= JSON.parse(totalAmount) - JSON.parse(totalPaidAmount)) {
      let data = {
        totalPrice: 0,
        paidAmount: amountDue,
        customer_id: orders[0].customerId,
        pendingBalance: 0,
        products: [
          {
            id: 1,
            quantity: 1,
            rate: amountDue,
          },
        ],
      };
      dispatch(Actions.clearPendingDues(data));
      toggle2();
      dispatch(Actions.setLoading(true));
      dispatch(Actions.getCustomerOrders(custId));
    } else {
      return toast.warn("Cannot pay more then dues");
    }
  };

  return (
    <div>
      {loading ? (
        <div id="myloader">
          <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
      ) : (
        <Container>
          <div className="mt-5">
            {orders === null ? (
              <div id="myloader">
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
              </div>
            ) : orders.length === 0 ? (
              <div className="text-center mt-4">
                <h2 className="py-4">No Orders</h2>
              </div>
            ) : (
              <div>
                <div className="cards-container">
                  <div className="info-cards">
                    <h4 className="text-white mb-1">Total Orders</h4>
                    <h5 className="text-white mb-0 ml-1">{orderCount}</h5>
                  </div>
                  <div className="info-cards">
                    <h4 className="text-white mb-1">Total Amount</h4>
                    {totalAmount === null ? (
                      <Spinner color="#fff" />
                    ) : (
                      <h5 className="text-white mb-0 ml-1">
                        {"\u20AC"}
                        {totalAmount}
                      </h5>
                    )}
                  </div>
                  <div className="info-cards">
                    <h4 className="text-white mb-1">Paid Amount</h4>
                    {totalPaidAmount === null ? (
                      <Spinner color="#fff" />
                    ) : (
                      <h5 className="text-white mb-0 ml-1">
                        {"\u20AC"}
                        {totalPaidAmount}
                      </h5>
                    )}
                  </div>
                  <div className="info-cards">
                    <h4 className="text-white mb-1">Due Amount</h4>
                    {totalAmount === null || totalPaidAmount === null ? (
                      <Spinner color="#fff" />
                    ) : (
                      <h5 className="text-white mb-0 ml-1">
                        {"\u20AC"}
                        {JSON.parse(totalAmount) - JSON.parse(totalPaidAmount)}
                      </h5>
                    )}
                  </div>
                </div>
                <div className="mx-4">
                  <h3 className="mb-0">{orders[0].customerName}</h3>
                  <Button color="primary" onClick={(e) => toggle2()}>
                    Clear Dues
                  </Button>
                </div>
                {orders.map((idx, index) => (
                  <div
                    className="pt-3 pb-4 pb-md-5 mx-4"
                    key={idx.id}
                    style={{ borderBottom: "1px dashed" }}
                  >
                    <h6>Payment #{idx.id}</h6>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <span>Payment ID:</span> <strong>{idx.id}</strong>
                      </li>
                      <li>
                        <span>Payment Date:</span>{" "}
                        <strong>{idx.saleDate}</strong>
                      </li>
                      <li>
                        <span>Payment Status:</span>{" "}
                        <strong>{idx.status}</strong>
                      </li>
                    </ul>
                    <div className="table-responsive my-4">
                      <table
                        className="table mb-0"
                        style={{ minWidth: "400px" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Net Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {idx.productsOrder.map((inv, idx) => (
                            <tr className="ordered-item" key={inv.id}>
                              <td className="ordered-name">
                                <span>{idx + 1}</span>
                              </td>
                              <td className="ordered-name">
                                <span>{inv.productName}</span>
                              </td>
                              <td className="ordered-quantity">
                                <span>{inv.quantity}</span>
                              </td>
                              <td className="ordered-price">
                                <span>
                                  {"\u20AC"}
                                  {inv.rate}
                                </span>
                              </td>
                              <td className="ordered-price">
                                <span>
                                  {"\u20AC"}
                                  {inv.rate * inv.quantity}.00
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h5 className="mb-1">Total</h5>
                      <div className="table-responsive">
                        <table>
                          <tbody>
                            <tr>
                              <td>Total Price :</td>
                              <td>
                                {"\u20AC"}
                                {idx.totalPrice}
                              </td>
                            </tr>
                            <tr>
                              <td>Paid Amount :</td>
                              <td>
                                {"\u20AC"}
                                {idx.paidAmount}
                              </td>
                            </tr>
                            <tr>
                              <td>Due Amount :</td>
                              <td>
                                {"\u20AC"}
                                {JSON.parse(idx.totalPrice) -
                                  JSON.parse(idx.paidAmount) <
                                0
                                  ? 0
                                  : JSON.parse(idx.totalPrice) -
                                    JSON.parse(idx.paidAmount)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Container>
      )}
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        className="modal-view modal-lg modal-dialog-centered"
      >
        <ModalHeader toggle={toggle2}></ModalHeader>
        {orders !== null && orders.length > 0 ? (
          <ModalBody>
            <div className="success-screen">
              <div
                className="thank-you text-center"
                style={{ padding: "20px" }}
              >
                <h2 className="text-white mb-0">Pay Now</h2>
              </div>
              <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
                <Row>
                  <Col lg={4}>
                    <h6 className="mb-0">Customer Information</h6>
                    <ul className="list-unstyled mb-0">
                      <li>{orders[0].customerName}</li>
                      <li>
                        <span>Pending dues:</span>{" "}
                        <strong>
                          {"\u20AC"}
                          {JSON.parse(totalAmount) -
                            JSON.parse(totalPaidAmount)}
                        </strong>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="ordered-detail border-top">
                <Container>
                  <Form className="mt-4" onSubmit={(e) => payDebt(e)}>
                    <FormGroup>
                      <Label for="exampleEmail">Amount</Label>
                      <Input
                        type="number"
                        name="amount"
                        id="exampleEmail"
                        min={1}
                        placeholder="Pay now..."
                        style={{ maxWidth: "300px" }}
                        required
                      />
                    </FormGroup>
                    <Button type="submit" color="primary" className="mr-2">
                      Save
                    </Button>
                    <Button
                      color="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        toggle2();
                      }}
                    >
                      Cancel
                    </Button>
                  </Form>
                </Container>
              </div>
            </div>
          </ModalBody>
        ) : null}
      </Modal>
    </div>
  );
}

export default CustomerOrders;
