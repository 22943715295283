import React from 'react';

function NotFound(){
    return(
        <div className="section-ptb">
        <div className="site-content">
            <div className="text-center">
                <h1>404! Page not found.</h1>
            </div>
        </div>
        </div>
    )
}

export default NotFound;