import React , {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Col,Modal, ModalBody, ModalFooter, ModalHeader, Form, Container, Label, Input, FormGroup, Button } from 'reactstrap';
import {Link} from 'react-router-dom';
import Domain from 'config.js'
import * as Actions from 'store/actions'


function AdminproductList(props) {
    const dispatch = useDispatch()
    const [modal1, setmodal1] = useState(false)
    const [modal2, setModal2] = useState(false)
    const toggle1 = () => {
        setmodal1(!modal1)
    }
    const toggle2 = () => {
        setModal2(!modal2)
    }
    const onDeleteInvoicePopup = () => {
        toggle1();
    }
    const submitDmg = e => {
        e.preventDefault()
        let data = {
            amount : e.target.amount.value,
            product_id: props.product.id
        }
        dispatch(Actions.adddmgGoods(data))
        toggle2()
    }
    const {product,deleteproduct} = props;
    return (
             <Col key={1} sm={6} lg={3}>
                     <div className="product product_tag-black product-hover-style-default">
                        <div className="product-inner element-hovered">
                        <div className="product-thumbnail">
                            <div className="product-thumbnail-inner">
                                {product.image ?
                                    <div className="product-thumbnail-main">
                                        <img src={`${Domain}${product.image}`} className="img-fluid" alt="productImage"/>
                                    </div>
                                :null}
                            </div>
                            <div className="product-action product-action-quick-view">
                                <Link to='#' onClick={toggle2} className="product-dmgGoods"><i className="fa fa-gavel"></i></Link>
                                <Link to={`/Product-edit/${product.id}`} className="open-edit-view"><i className="fa fa-pencil-square-o"></i></Link>
                                <Link to="#" className="product-delete" onClick={() => onDeleteInvoicePopup()} ><i className="fa fa-trash-o"></i></Link>
                            </div>
                        </div>
                        <div className="product-info">
                            <span className="ciyashop-product-category">
                                <span>{product.category}</span>
                            </span>
                            {product.name ?
                            <h3 className="product-name">
                                <Link to={`/Product-edit/${product.id}`}>{product.name}</Link>
                            </h3>
                            : null }
                            {product.salePrice ?
                                <span className="price">
                                <ins>
                                    <span className="price-amount amount">
                                    <span className="currency-symbol">{'\u20AC'}</span>{product.salePrice}
                                    </span>
                                </ins>
                                </span>
                            : null }
                        </div>
                        </div>
                    </div>

                    {/* modal-delete */}
                    <Modal isOpen={modal1} toggle={toggle1} className="modal-delete modal-lg modal-dialog-centered">
                        <ModalHeader toggle={toggle1}  ></ModalHeader>
                        <ModalBody>
                             Are You Sure You Want To Delete This Product ?
                        </ModalBody>
                        <ModalFooter className="justify-content-center pt-4" >
                            <Link to="#" className="action-button"  onClick={(res)=>deleteproduct(toggle1())}>Yes</Link>
                            <Link to="#" className="action-button no"  onClick={toggle1}>No</Link>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal2} toggle={toggle2} className="modal-lg modal-dialog-centered">
                        <ModalHeader toggle={toggle2}></ModalHeader>
                        <ModalBody className='p-4'>
                             <h2 className='text-center'>Add this Product to Damage Goods?</h2>
                             <div className=" border-top">
                            <Container className="themed-container" fluid="md">
                            <Form className='mt-4' onSubmit={e => submitDmg(e)}>
                                <FormGroup>
                                <Label for="exampleEmail">Amount</Label>
                                <Input type="number" name="amount" id="exampleEmail" min={1} placeholder="Add to damage goods..." style={{maxWidth: '300px'}}/>
                                </FormGroup>
                                <Button type='submit' color='primary' className='mr-2'>Save</Button>
                                <Button
                                color='danger'
                                onClick= {e => {
                                e.preventDefault()
                                toggle2()
                                }}
                                >Cancel</Button>
                            </Form>
                            </Container>
                            </div>
                        </ModalBody>
                    </Modal>
                </Col>
        )
}

export default AdminproductList;
