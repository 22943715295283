import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'store/actions'
import { Row, Col,Container,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Loader from 'react-loader-spinner';
import history from '@history'

function Expense(){
    const dispatch = useDispatch()
    const [expense, setExpense] = useState(null)
    const [expCount, setExpCount] = useState(null)
    const [page, setPage] = useState(1)
    const Exp = useSelector(({app}) => app.Expense)
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        if(Auth){
            dispatch(Actions.getExpense(page))
        }
    },[dispatch,page,Auth])
    useEffect(() => {
        setExpense(Exp.rows)
        setExpCount(Exp.totalExp)
    },[Exp])
    const paginate = (e) => {
        let pg = e.selected +1
        setPage(pg)
      }
    if(Auth === false){
        history.push({
            pathname: '/'
        })
    }
    if(expense === null){
      return(
        <div id="preloader">
          <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
      )
    }

return(
    <div className="section-ptb">
      <Container className="themed-container" fluid="md">
        <Row>
        <Col lg={12}>
            <div className="mb-0">
              <h4>Expense List</h4>
            </div>
            <div style={{overflowX: 'auto'}}>
              <Table striped style={{minWidth: '800px'}}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Expense Date</th>
                    <th>Category</th>
                    <th>Details</th>
                    <th>Amount</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {expense.length === 0 ?
                <tbody><tr><th colSpan={7} className='text-center'>No Customers Found!</th></tr></tbody>:
                <tbody>
                  {expense.map(cust => (
                    <tr key={cust.id}>
                      <td>{cust.id}</td>
                      <td>{cust.expenseDate}</td>
                      <td>{cust.category}</td>
                      <td>{cust.expenseFor}</td>
                      <td>{cust.amount}</td>
                      <td>{cust.created_at}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle caret size ='sm' color="primary">
                            Action
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="nav-link" onClick= {e => {history.push({pathname: `expense-edit/${cust.id}`})}}>Edit</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
                }
              </Table>
          </div>
          </Col>
        </Row>
        <Row>
          <Col>
          {expCount && expCount >10 ?
          <ReactPaginate
            pageCount = {expCount/10}
            initialPage = {0}
            onPageChange = {e => paginate(e)}
            activeClassName={'active'}
            containerClassName={'pagination332'}
            subContainerClassName={'pages pagination'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            breakLabel={'...'}
          />
          : <></>}
          </Col>
        </Row>
      </Container>
    </div>
)
}
export default Expense;