import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '@history'
import * as Actions from 'store/actions'
export const GET_CUSTOMERS = '[APP] GET_CUSTOMERS';
export const ADD_CUSTOMER = '[APP] ADD_CUSTOMER';
export const CUSTOMER_DETAILS = '[APP] CUSTOMER_DETAILS';
export const UPDATE_CUSTOMER = '[APP] UPDATE_CUSTOMER';
export const CUSTOMER_ORDERS = '[APP] CUSTOMER_ORDERS';
export const CLEAR_CUST_ORDER = '[APP] CLEAR_CUST_ORDER';

export function getCustomers(page) {
    const request = axios.get(`${Domain}/getcustomers`, {
        params : {page}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_CUSTOMERS,
                payload: response.data.result
            })
        }
        )
    )
}
export function addCustomer(data) {
    const request = axios.post(`${Domain}/addCustomer`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_CUSTOMER,
            })
        }
        ).then(() => history.push({pathname: '/customers'}))
        .then(() => toast.success('Customer Added Successfully'))
        .catch(() => toast.warn('Cannot Add Customer'))
    )
}
export function customerDetails(id) {
    const request = axios.get(`${Domain}/customerDetails`, {
        params: {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: CUSTOMER_DETAILS,
                payload: response.data.result
            })
        }
        ).catch((error) => console.log(error))
    )
}
export function updateCustomer(data) {
    const request = axios.put(`${Domain}/updateCustomer`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: UPDATE_CUSTOMER,
            })
        }
        ).then(() => history.push({pathname: '/customers'}))
        .then(() => toast.success('Customer updated successfully'))
        .catch((error) => {
            console.log(error)
            toast.warn('Cannot update customer')
        })
    )
}
export function getCustomerOrders(id){
    const request = axios.get(`${Domain}/ordersByCustomer`, {
        params: { id }
    })
    return (dispatch) => {
        request.then(resp => {
            return dispatch({
                type: CUSTOMER_ORDERS,
                payload: resp.data.orders
            })
        }).then(() => dispatch(Actions.setLoading(false)))
        .catch(error => {
            console.log(error);
        })
    }
}