/**
 *  Admin Site Product Add
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row,Container, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import * as Actions from 'store/actions'
import history from '@history';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

function Productadd(){
    const dispatch = useDispatch()
     const Category = useSelector(({app}) => app.Categories)
     const auth = useSelector(({auth}) => {
         return auth.login.success ? auth.user : false
     })
    const loadingData = useSelector(({app}) => app.Loading);
    const [newForm, setNewForm] = useState(null)
    const [Cat, setCat] = useState(null)
    const [newpic, setNewPIc] = useState(null)
    useEffect(() => {
        setCat(Category)
    },[Category])
    useEffect(() => {
        if(auth === false){
            history.push({
                pathname: '/'
            })
        }
    },[auth])
    const formChange =(e) => {
        setNewForm({
            ...newForm,
            [e.target.name] : e.target.value
        })
    }
    const Uploadimage = (e) => {
        setNewPIc(e)
        }
    const formSubmit = (e) => {
        e.preventDefault()
        dispatch(Actions.setLoading(true))
        if(newpic === null){
            toast.warn('Please choose image')
        } else {
            const fx = new FormData();
            fx.append('name', newForm.name)
            fx.append('salePrice', newForm.salePrice)
            fx.append('buyPrice', newForm.buyPrice)
            fx.append('stock', newForm.stock)
            fx.append('brand', newForm.brand)
            fx.append('SKU', newForm.SKU)
            fx.append('details', newForm.details)
            fx.append('category_id', newForm.category)
            fx.append('image', newpic[0])
            dispatch(Actions.addProduct(fx))
        }
    }
    return(
        <div className="site-content">
            <div className="content-wrapper section-ptb">
                <Container className="themed-container" fluid="md">
                    <div className="product-content-top single-product single-product-edit">
                        {loadingData === false ?
                        <Row>
                        <div className="product-top-right col-xl-7 col-md-6">
                                <div className="product-top-right-inner">
                                    <form onSubmit={e => formSubmit(e)} className="summary entry-summary">
                                            <FormGroup className="edit-icon">
                                                <Input type="text" name = 'name' className="form-control product_title" placeholder="Product Name" onChange= {e=> formChange(e)} required/>
                                            </FormGroup>
                                            <FormGroup className="edit-icon">
                                                <Input type="text" name = 'salePrice' className="form-control price" placeholder="Product Sale Price" onChange= {e=> formChange(e)} required/>
                                            </FormGroup>
                                            <FormGroup className="edit-icon">
                                                <Input type="text" name = 'buyPrice' className="form-control price" placeholder="Product Buy Price" onChange= {e=> formChange(e)} required/>
                                            </FormGroup>
                                            <FormGroup className="edit-icon">
                                                <Input  type="textarea" name = 'details' className="form-control" rows="3" placeholder="Product Description" onChange= {e=> formChange(e)} />
                                            </FormGroup>
                                            <FormGroup className="edit-icon">
                                                <Input type="text" name = 'brand' className="form-control price" placeholder="Brand" onChange= {e=> formChange(e)}  required/>
                                            </FormGroup>
                                                <FormGroup>
                                            <Label className="title pl-0">Category</Label>
                                            <select name = 'category' className="form-control"  onChange= {e=> formChange(e)} required>
                                                <option value=''>Select Category</option>
                                                {Cat && Cat!==null ?
                                                Cat.map(cat => (
                                                <option value={cat.id} key={cat.id}>{cat.name}</option>
                                                )) : <option value="cat.id">wait</option>}
                                            </select>
                                            </FormGroup>
                                            <FormGroup>
                                            <Label className="title pl-0">SKU</Label>
                                            <input type="text" name = 'SKU' className="form-control" placeholder="SKU" onChange= {e=> formChange(e)} required></input>
                                            </FormGroup>
                                            <FormGroup>
                                            <Label className="title pl-0">Product Stock</Label>
                                            <input type="text" name = 'stock' className="form-control" placeholder="Product Stock" onChange= {e=> formChange(e)} required></input>
                                            </FormGroup>
                                            <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                            <Link to="/admin-panel/Product" className="btn btn-danger mb-2"> Cancel </Link>
                                    </form>
                                </div>
                            </div>
                            <div className="product-top-left col-xl-5 col-md-6">
                                <div className="ciyashop-product-thumbnail__image">
                                    <img src={require(`../../assets/images/img-placeholder.png`)}  className="img-fluid" alt="upladed"/>
                                    <div className="d-flex justify-content-center image-content align-items-center">
                                        <ImageUploader
                                            buttonText=""
                                            withIcon={false}
                                            withPreview={true}
                                            onChange={Uploadimage}
                                            maxFileSize={3000000}
                                            fileSizeError = 'File Too Big'
                                            imgExtension={['.jpg', '.jpeg', '.png']}/>
                                    </div>
                                </div>
                            </div>
                        </Row>:
                        <div id="preloader">
                            <Loader type="Puff" color="#04d39f" height={100} width={100} />
                        </div>
                        }
                    </div>
                </Container>
            </div>
        </div>
    )
}
export default Productadd;
