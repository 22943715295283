import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Row, FormGroup, Input, Container } from 'reactstrap';
import * as Actions from 'store/actions'
import { Link } from 'react-router-dom';

function AddCustomer(props){
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(null)
    const formChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const formSubmit = (e) => {
        e.preventDefault()
        dispatch(Actions.addCustomer(formData))
    }
    return(
        <div className="site-content">
            <div className="content-wrapper section-ptb">
                <Container>
                    <h2>Add Customer</h2>
                <Row>
                    <div className="product-top-right col-xl-7 col-md-6">
                        <div className="product-top-right-inner">
                            <form onSubmit={e => formSubmit(e)}>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'fullName' placeholder="Customer Name" onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'phone' placeholder="Phone" onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'address' placeholder="Address" onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input  type="textarea" name = 'detail' rows="3" placeholder="Details(Optional)" onChange= {e=> formChange(e)} />
                                    </FormGroup>
                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                    <Link to="/customers" className="btn btn-danger mb-2"> Cancel </Link>
                            </form>
                        </div>
                    </div>
                </Row>
                </Container>
            </div>
        </div>
    )

}

export default AddCustomer;