import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as Actions from "store/actions";
import "react-tabs/style/react-tabs.css";
import CanvasJSReact from "../../assets/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ExpenseGraph = () => {
  const dispatch = useDispatch();
  const [expDay, setExpDay] = useState(null);
  const [expWeek, setExpWeek] = useState(null);
  const [expMonth, setExpMonth] = useState(null);

  const expGraphData = useSelector(({ app }) => app.expGraph);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(Actions.expenseGraph());
  }, [dispatch]);

  useEffect(() => {
    if (expGraphData) {
      setExpDay(expGraphData.day);
      setExpWeek(expGraphData.week);
      setExpMonth(expGraphData.month);
    }
  }, [expGraphData]);

  const ExpDayOptions = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "DDD DD MMM",
      intervalType: "day",
    },
    axisY: {
      title: "Expense",
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: expDay,
      },
    ],
  };
  const ExpWeekOptions = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "DDD DD MMM",
      intervalType: "week",
    },
    axisY: {
      title: "Expense",
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: expWeek,
      },
    ],
  };
  const ExpMonthOptions = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "MMM YYYY",
      intervalType: "month",
    },
    axisY: {
      title: "Expense",
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#",
        xValueFormatString: "MMM YYYY",
        type: "spline",
        dataPoints: expMonth,
      },
    ],
  };
  return (
    <Tabs>
      <TabList>
        <Tab>Daily</Tab>
        <Tab>Weekly</Tab>
        <Tab>Monthly</Tab>
      </TabList>
      <TabPanel>
        <CanvasJSChart options={ExpDayOptions} />
      </TabPanel>
      <TabPanel>
        <CanvasJSChart options={ExpWeekOptions} />
      </TabPanel>
      <TabPanel>
        <CanvasJSChart options={ExpMonthOptions} />
      </TabPanel>
    </Tabs>
  );
};
export default ExpenseGraph;
