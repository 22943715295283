import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, FormGroup, Input, Container, Table } from 'reactstrap';
import * as Actions from 'store/actions'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const style = {
    pdding : {
        padding : '10px',
        width : '100%'
    },
    tble : {
        minWidth : '200px',
        overflowX : 'auto',
    }
}
function AddCategory(props){
    const dispatch = useDispatch()
    let init = {name : ''}
    const [formData, setFormData] = useState(init)
    const [cats, setCats] = useState(null)
    const catData = useSelector(({app}) => app.Categories)
    const formChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    useEffect(() => {
        if(catData){
            setCats(catData)
        }
    },[catData])
    const formSubmit = (e) => {
        e.preventDefault()
        dispatch(Actions.addCategory(formData))
        setFormData(init)
    }
    const removeItem = (id) => {
        dispatch(Actions.deleteCategory(id))
    }
    return(
        <div className="site-content">
            <div className="content-wrapper">
                <Container className="themed-container" fluid="md">
                    <Row style={{paddingTop: '80px'}}>
                    <div style={style.pdding}>
                    <div className="product-top-right col-xl-7 col-md-6" style={{margin: '0 auto'}}>
                    <h2>Add Category</h2>
                        <div className="product-top-right-inner">
                            <form onSubmit={e => formSubmit(e)}>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'name' value={formData.name} placeholder="Category Name" onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                    <Link to="/customers" className="btn btn-danger mb-2"> Cancel </Link>
                            </form>
                        </div>
                    </div>
                    </div>
                    </Row>
                    <Row style={{paddingTop: '20px'}}>
                    <div style={style.pdding}>
                    <div className="product-top-right col-xl-7 col-md-6" style={{margin: '0 auto', maxHeight: '500px', overflowY : 'auto'}}>
                    <h2>Category List</h2>
                    <Table style= {style.tble}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        {cats !== null ?
                        <tbody>
                            {cats.map(cat => (
                                <tr key={cat.id}>
                                <td>{cat.id}</td>
                                <td>{cat.name}</td>
                                <td>{cat.created_at}</td>
                                <td><Link className="delete-button" onClick ={e => removeItem(cat.id)} to ='#'><i className="fa fa-trash-o pl-2"></i></Link></td>
                            </tr>
                            ))}
                        </tbody> :
                        <tbody>
                        <tr>
                            <td colSpan={3}>No Category Found</td>
                        </tr>
                        </tbody>
                        }
                    </Table>
                    </div>
                    </div>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default AddCategory;