import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container, CardTitle, Card, CardText } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from "react-loader-spinner";
import * as Actions from "store/actions";
import "react-tabs/style/react-tabs.css";
import CanvasJSReact from "../../assets/canvasjs.react";
import ExpenseGraph from "./ExpenseGraph";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Reports() {
  const dispatch = useDispatch();
  const [splineDay, setSplineDay] = useState(null);
  const [splineWeek, setSplineWeek] = useState(null);
  const [splineMonth, setSplineMonth] = useState(null);
  const [barDay, setBarDay] = useState(null);
  const [barWeek, setBarWeek] = useState(null);
  const [barMonth, setBarMonth] = useState(null);
  const [aggrData, setAggrData] = useState(null);
  const [pieGraph, setPieGraph] = useState(null);
  const [netProfit, setNetProfit] = useState(null);
  const splineGraph = useSelector(({ app }) => app.splineGraph);
  const barGraph = useSelector(({ app }) => app.barGraph);
  const statsData = useSelector(({ app }) => app.orderAggr);
  const pieData = useSelector(({ app }) => app.pieGraph);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (Auth) {
      dispatch(Actions.splineGraph());
      dispatch(Actions.barGraph());
      dispatch(Actions.orderAggr());
      dispatch(Actions.pieGraph());
    }
  }, [dispatch, Auth]);
  useEffect(() => {
    if (splineGraph) {
      setSplineDay(splineGraph.day);
      setSplineWeek(splineGraph.week);
      setSplineMonth(splineGraph.month);
    }
  }, [splineGraph]);
  useEffect(() => {
    if (pieData) {
      setPieGraph(pieData.graph);
      setNetProfit(pieData.netProfit);
    }
  }, [pieData]);
  useEffect(() => {
    if (barGraph) {
      setBarDay(barGraph.day);
      setBarWeek(barGraph.week);
      setBarMonth(barGraph.month);
    }
  }, [barGraph]);
  useEffect(() => {
    setAggrData(statsData);
  }, [statsData]);

  const DayOptions = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "DDD DD MMM",
      intervalType: "day",
    },
    axisY: {
      title: "Sales",
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: splineDay,
      },
    ],
  };
  const WeekOptions = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "DDD DD MMM",
      intervalType: "week",
    },
    axisY: {
      title: "Sales",
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: splineWeek,
      },
    ],
  };
  const MonthOptions = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "MMM YYYY",
      intervalType: "month",
    },
    axisY: {
      title: "Sales",
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#",
        xValueFormatString: "MMM YYYY",
        type: "spline",
        dataPoints: splineMonth,
      },
    ],
  };
  const barDayOption = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "DDD DD MMM",
      intervalType: "day",
    },
    axisY: {
      title: "Sales",
      prefix: "\u20AC",
      includeZero: false,
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        xValueFormatString: "DDD DD MMM",
        type: "column",
        dataPoints: barDay,
      },
    ],
  };
  const barWeekOption = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "DDD DD MMM",
      intervalType: "week",
    },
    axisY: {
      title: "Sales",
      prefix: "\u20AC",
      includeZero: false,
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        xValueFormatString: "DDD DD MMM",
        type: "column",
        dataPoints: barWeek,
      },
    ],
  };
  const barMonthOption = {
    animationEnabled: true,
    title: {
      horizontalAlign: "left",
    },
    axisX: {
      valueFormatString: "MMM YYYY",
      intervalType: "month",
    },
    axisY: {
      title: "Sales",
      prefix: "\u20AC",
      includeZero: false,
    },
    data: [
      {
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        xValueFormatString: "MMM YYYY",
        type: "column",
        dataPoints: barMonth,
      },
    ],
  };
  const PieChart = {
    animationEnabled: true,
    title: {
      text: "Net Profit",
    },
    legend: {
      maxWidth: 350,
      itemWidth: 120,
    },
    data: [
      {
        type: "pie",
        showInLegend: true,
        legendText: "{indexLabel}",
        dataPoints: pieGraph,
      },
    ],
  };
  return (
    <div className="section-ptb">
      <Container className="themed-container" fluid="md">
        {aggrData !== null && aggrData !== undefined && netProfit !== null ? (
          <Row>
            <Col md="3" className="py-2">
              <Card
                body
                inverse
                color="success"
                style={{
                  borderRadius: "0",
                  boxShadow: "10px 10px 10px #d8d8d8",
                }}
              >
                <CardTitle>Total Sales Aggregate</CardTitle>
                <CardText style={{ fontSize: "22px" }}>
                  {"\u20AC"}
                  {aggrData.salesAmount}
                </CardText>
              </Card>
            </Col>
            <Col md="3" className="py-2">
              <Card
                body
                inverse
                color="info"
                style={{
                  borderRadius: "0",
                  boxShadow: "10px 10px 10px #d8d8d8",
                }}
              >
                <CardTitle>Total Purchase Aggregate</CardTitle>
                <CardText style={{ fontSize: "22px" }}>
                  {"\u20AC"}
                  {aggrData.totalAmount}
                </CardText>
              </Card>
            </Col>
            <Col md="3" className="py-2">
              <Card
                body
                inverse
                color="warning"
                style={{
                  borderRadius: "0",
                  boxShadow: "10px 10px 10px #d8d8d8",
                }}
              >
                <CardTitle>Total Number of Sales</CardTitle>
                <CardText style={{ fontSize: "22px" }}>
                  {aggrData.totalCount}
                </CardText>
              </Card>
            </Col>
            <Col md="3" className="py-2">
              <Card
                body
                inverse
                color="primary"
                style={{
                  borderRadius: "0",
                  boxShadow: "10px 10px 10px #d8d8d8",
                }}
              >
                <CardTitle>Net Profit</CardTitle>
                <CardText style={{ fontSize: "22px" }}>
                  {"\u20AC"}
                  {netProfit}
                </CardText>
              </Card>
            </Col>
          </Row>
        ) : (
          <div style={{ height: "100px", position: "relative" }}>
            <div id="myloader">
              <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
          </div>
        )}
        <Row className="mt-6">
          <Col lg={12}>
            <div className="d-sm-flex reports-tab w-100 mb-0">
              <h4>Reports</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="7">
            <Tabs>
              <TabList>
                <Tab>Daily</Tab>
                <Tab>Weekly</Tab>
                <Tab>Monthly</Tab>
              </TabList>
              <TabPanel>
                <CanvasJSChart options={barDayOption} />
              </TabPanel>
              <TabPanel>
                <CanvasJSChart options={barWeekOption} />
              </TabPanel>
              <TabPanel>
                <CanvasJSChart options={barMonthOption} />
              </TabPanel>
            </Tabs>
          </Col>
          <Col md="5">
            <CanvasJSChart options={PieChart} />
          </Col>
        </Row>
        <Row className="mt-6">
          <Col md="12">
            <div
              className="title text-center py-4"
              style={{ fontWeight: "600", fontSize: "26px" }}
            >
              Sales Graph
            </div>
            <Tabs>
              <TabList>
                <Tab>Daily</Tab>
                <Tab>Weekly</Tab>
                <Tab>Monthly</Tab>
              </TabList>
              <TabPanel>
                <CanvasJSChart options={DayOptions} />
              </TabPanel>
              <TabPanel>
                <CanvasJSChart options={WeekOptions} />
              </TabPanel>
              <TabPanel>
                <CanvasJSChart options={MonthOptions} />
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col md="12">
            <div
              className="title text-center py-4"
              style={{ fontWeight: "600", fontSize: "26px" }}
            >
              Expense Graph
            </div>
            <ExpenseGraph />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Reports;
