import React from "react";
import { useSelector } from 'react-redux';
import { Route, Switch } from "react-router-dom";
// pages for this kit
import './styles.js'
import Sales from "./component/Sales/Sales";
import LoginPage from "./component/Login/loginPage";
import Reports from "./component/dashboard/Reports";
import Customers from "./component/Customers/Customers";
import Productadd from "./component/Products/Productadd";
import AdminProduct from "./component/Products/AdminProductList";
import Productedit from "./component/Products/Productedit";
import AddCustomer from "./component/Customers/AddCustomer";
import EditCustomer from "./component/Customers/EditCustomer";
import Pos from "./component/POS/Pos";
import AddCategory from "./component/Categories/AddCategory";
import AddExpense from "./component/Expense/AddExpense";
import Expense from "./component/Expense/Expense";
import DmgGoods from "./component/Expense/DmgGoods";
import NotFound from "./component/NotFound/NotFound";
import AdminHeader from "./component/Header/Adminheader";
import EditExpense from "./component/Expense/EditExpense.js";
import InvoiceDetailCon from "./component/Sales/invoiceDetail";
import CustomerOrders from "./component/Customers/CustomerOrders.js";


function App(){
    const isLoggedIn = useSelector(({auth}) => {
        return auth.login.success ? true : false
    })
    return(
        <div>
            {isLoggedIn ?
            <AdminHeader/> : <></>
            }
            <Switch>
                <Route path="/" exact render={props => <LoginPage {...props} />} />
                <Route path="/dashboard" exact render={props => <Reports {...props} />} />

                <Route path="/sales" exact render={props => <Sales {...props} />} />
                <Route path={`/invoice/:id`} exact render={props => <InvoiceDetailCon {...props} />} />

                <Route path="/customers" exact render={props => <Customers {...props} />} />
                <Route path="/add-customers" exact render={props => <AddCustomer {...props} />} />
                <Route path={`/Customer-edit/:id`} exact render={props => <EditCustomer {...props} />} />
                <Route path={`/Customer-orders/:id`} exact render={props => <CustomerOrders {...props} />} />

                <Route path="/add-product" exact render={props => <Productadd {...props} />} />  
                <Route path="/products" exact render={props => <AdminProduct {...props} />} />
                <Route path={`/Product-edit/:id`} exact render={props => <Productedit {...props} />} />

                <Route path="/Pos" exact render={props => <Pos {...props} />} />

                <Route path="/add-category" exact render={props => <AddCategory {...props} />} />

                <Route path="/expense" exact render={props => <Expense {...props} />} />
                <Route path="/add-expense" exact render={props => <AddExpense {...props} />} />
                <Route path={`/expense-edit/:id`} exact render={props => <EditExpense {...props} />} />

                <Route path="/dmggoods" exact render={props => <DmgGoods {...props} />} />

                <Route component={NotFound}/>
            </Switch>
        </div>
    )
}
export default App;