import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '@history'
export const GET_EXPENSE = '[APP] GET_EXPENSE';
export const GET_EXPENSEDETAILS = '[APP] GET_EXPENSEDETAILS';
export const ADD_EXPENSE = '[APP] ADD_EXPENS';
export const UPDATE_EXPENSE = '[APP] UPDATE_EXPENSE';
export const ADD_DMGGOODS = '[APP] ADD_DMGGOODS';
export const DELETE_DMGGOODS = '[APP] DELETE_DMGGOODS';
export const GET_DMGGOODS = '[APP] GET_DMGGOODS';
export const GET_EXPGRAPH = '[APP] GET_EXPGRAPH';

export function getExpense(page) {
    const request = axios.post(`${Domain}/getExpense`, {
        params : {page}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_EXPENSE,
                payload: response.data.result
            })
        }
        )
    )
}
export function expenseDetails(id) {
    const request = axios.get(`${Domain}/expenseDetails`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_EXPENSEDETAILS,
                payload: response.data.result
            })
        }
        )
    )
}
export function addExpense(data) {
    const request = axios.post(`${Domain}/addExpense`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_EXPENSE,
            })
        }
        ).then(() => toast.success('Expense Added Successfully'))
        .catch(() => toast.warn('Cannot Add Expense'))
    )
}
export function updateExpense(data){
    const request = axios.put(`${Domain}/updateExpense`, data);
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: UPDATE_EXPENSE,
            })
        }
        ).then(() => history.push({pathname: '/expense'}))
        .then(() => toast.success('Expense Added Successfully'))
        .catch(() => toast.warn('Cannot Add Expense'))
    )
}

export function expenseGraph() {
    const request = axios.get(`${Domain}/expenseGraph`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_EXPGRAPH,
                payload: response.data.result
            })
        }
        )
    )
}

export function getdmgGoods(page) {
    const request = axios.get(`${Domain}/getdmgGoods`, {
        params : {page}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_DMGGOODS,
                payload: response.data.result
            })
        }
        )
    )
}

export function adddmgGoods(data) {
    const request = axios.post(`${Domain}/adddmgGoods`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_DMGGOODS,
            })
        }
        ).then(() => toast.success('Expense Added Successfully'))
        .catch(() => toast.warn('Cannot Add Expense'))
    )
}

export function deleteDmgGoods(id) {
    const request = axios.delete(`${Domain}/deleteDmgGoods`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: DELETE_DMGGOODS,
            })
        }
        ).then(() => dispatch(getdmgGoods(1)))
        .then(() => toast.success('Expense deleted Successfully'))
        .catch(() => toast.warn('Cannot delete Expense'))
    )
}