import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import * as userActions from './store/actions';
import { bindActionCreators } from 'redux';
import jwtService from 'services/jwtService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class Auth extends Component {

    state = {
        waitAuthCheck: true
    }

    componentDidMount() {
        return Promise.all([
            // Comment the lines which you do not use
            this.jwtCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false })
        })
    }

    jwtCheck = () => new Promise(resolve => {

        jwtService.on('onAutoLogin', () => {
            jwtService.signInWithToken()
                .then(user => {

                    this.props.setUserData(user);
                    this.props.SetLogin();

                    resolve();

                    toast.success("Auto Logged in with JWT");
                })
                .catch(error => {
                    toast.success("Token Expired");
                    this.props.logout();
                    resolve();
                })
        });

        jwtService.on('onAutoLogout', (message) => {

            if (message) {
                toast.warn("Auto Logout")
            }

            this.props.logout();

            resolve();
        });

        jwtService.on('onNoAccessToken', () => {
            resolve();
        });

        jwtService.init();

        return Promise.resolve();
    })

    render() {
        return (
            <>
            <ToastContainer autoClose={2500} />
            {this.state.waitAuthCheck ?
                <div id="preloader">
                    <Loader visible= {this.state.waitAuthCheck} type="Puff" color="#04d39f" height={100} width={100} />
                </div>:
                <React.Fragment children={this.props.children} />}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: userActions.logoutUser,
        setUserData: userActions.setUserData,
        SetLogin: userActions.SetLogin,
    },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
