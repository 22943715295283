import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'store/actions'
import { Row, Col,Container} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import history from '@history'
import PosItems from './PosItems';
import PosSystem from './PosSystem';

function Pos(){
    const dispatch = useDispatch()
    const [productList, setProductList] = useState(null);
    const [productCount, setProductCount] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [CustomerList, setCustomerList] = useState(null);
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const productData = useSelector(({app}) => {
        return app ? app.Products : false
    })
    const customerData = useSelector(({app}) => {
        return app ? app.Customers : false
    })
    useEffect(() => {
        if(Auth === false){
            history.push({
                pathname: '/'
            })
        }
    },[Auth])
    useEffect(() => {
        if(Auth){
            dispatch(Actions.setLoading(true))
            dispatch(Actions.getProducts(currentPage))
        }
    }, [Auth,currentPage,dispatch])
    useEffect(() => {
        if(Auth){
            dispatch(Actions.getCustomers())
        }
    }, [Auth,dispatch])
    useEffect(() => {
        if(productData){
            setProductList(productData.rows)
            setProductCount(productData.totalProducts)
        }
    },[productData])
    useEffect(() => {
        if(customerData){
            setCustomerList(customerData.rows)
        }
    },[customerData])
    const onPageChanged = e => {
        let pg = e.selected +1
        setCurrentPage(pg)
        };
    return(
            <div className="site-content">
            <div className="content-wrapper section-ptb">
                <Container className="themed-container" fluid="md">
                    <Row>
                        <Col md='6'>
                            <PosItems products = {productList}/>
                            {productCount && productCount >12 ?
                            <ReactPaginate
                                pageCount = {productCount/12}
                                initialPage = {currentPage-1}
                                onPageChange = {e => onPageChanged(e)}
                                activeClassName={'active'}
                                containerClassName={'pagination332'}
                                subContainerClassName={'pages pagination'}
                                breakClassName={'break-me'}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                breakLabel={'...'}
                            />
                            : <></>}
                        </Col>
                        <Col md='6'>
                            <PosSystem customers = {CustomerList}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
    )
}
export default Pos;