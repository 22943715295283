/**
 *  Admin Site Product Edit Page
 */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import ProductEditDetail from './ProductEditDetail';
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'

function Productedit(props){
    const dispatch = useDispatch();
    const productId = parseInt(props.match.params.id);
    const [currentProduct, setCurrentProduct] = useState(null)
    const theProduct = useSelector(({app}) => app.Products.ProductDetails);
    const auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        if(auth){
            dispatch(Actions.productDetails(productId))
        }
    }, [auth,dispatch,productId])
    useEffect(() => {
        setCurrentProduct(theProduct)
    },[theProduct])
    if(!currentProduct || currentProduct === null){
        return(
        <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
        )}
    return(
            <div>
                {currentProduct !== null ?
                    <div className="site-content">
                    <div className="content-wrapper section-ptb">
                        <Container className="themed-container" fluid="md">
                            <ProductEditDetail product={currentProduct} />
                        </Container>
                    </div>
                    </div>

                :
                    null
                }
            </div>
    )
}
export default Productedit;
