/**
 *  Admin Invoive Page
 */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import * as Actions from "store/actions";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import history from "@history";

let salStyle = {
  headDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: "10px",
  },
  h4s: {
    display: "inline-block",
  },
  dateSelc: {
    display: "inline-block",
    paddingRight: "10px",
  },
};
function Sales(props) {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [orderCount, setOrderCount] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [mdata, setMdata] = useState(null);
  const [page, setPage] = useState(1);
  const [createdAt, setCreatedAt] = useState(null);
  const [status, setStatus] = useState(null);
  const invData = useSelector(({ app }) => app.Orders);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(Actions.getOrders(page, status, createdAt));
  }, [page, status, createdAt, dispatch]);
  useEffect(() => {
    if (invData) {
      setInvoices(invData.rows);
      setOrderCount(invData.totalOrders);
    }
  }, [invData]);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const paginate = (e) => {
    let pg = e.selected + 1;
    setPage(pg);
  };
  const changeStatus = async (e) => {
    let chStatus = e.target.value;
    setStatus(chStatus);
  };
  const changeDate = (e) => {
    let srchDate = e.target.value;
    setCreatedAt(srchDate);
  };
  if (invoices === null) {
    return (
      <div id="preloader">
        <Loader type="Puff" color="#04d39f" height={100} width={100} />
      </div>
    );
  }

  return (
    <>
      <div className="section-ptb">
        <Container className="themed-container" fluid="md">
          <Row>
            <Col lg={12}>
              <div style={salStyle.headDiv}>
                <h4>Sales List</h4>
                <div>
                  <div style={salStyle.dateSelc}>
                    <Input
                      type="date"
                      placeholder="Select date"
                      onChange={(e) => changeDate(e)}
                    />
                  </div>
                  <div style={salStyle.dateSelc}>
                    <select
                      style={salStyle.h4s}
                      onChange={(e) => changeStatus(e)}
                    >
                      <option value="all">Select Status</option>
                      <option value="partial">partial</option>
                      <option value="paid">paid</option>
                      <option value="unpaid">unpaid</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: "auto" }}>
                <Table striped style={{ minWidth: "800px" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sale Date</th>
                      <th>Total Price</th>
                      <th>Paid Amount</th>
                      <th>Status</th>
                      <th>Due Amount</th>
                      <th>Customer Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {invoices.length === 0 ? (
                    <tbody>
                      <tr>
                        <th colSpan={8} className="text-center">
                          No Invoices Found!
                        </th>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {invoices.map((inv) => (
                        <tr key={inv.id}>
                          <td>{inv.id}</td>
                          <td>{inv.saleDate}</td>
                          <td>{inv.totalPrice}</td>
                          <td>{inv.paidAmount}</td>
                          <td>{inv.status}</td>
                          <td>
                            {inv.totalPrice - inv.paidAmount > 0
                              ? inv.totalPrice - inv.paidAmount
                              : 0}
                          </td>
                          <td>{inv.customerName}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle caret size="sm" color="primary">
                                Action
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  className="nav-link"
                                  onClick={(e) => {
                                    history.push({
                                      pathname: `invoice/${inv.id}`,
                                    });
                                  }}
                                >
                                  View sale
                                </DropdownItem>
                                <DropdownItem
                                  className="nav-link"
                                  onClick={(e) => {
                                    setMdata(inv);
                                    toggle();
                                  }}
                                >
                                  View payment
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {orderCount !== null && orderCount > 20 ? (
                <ReactPaginate
                  pageCount={orderCount / 20}
                  initialPage={0}
                  onPageChange={(e) => paginate(e)}
                  activeClassName={"active"}
                  containerClassName={"pagination332"}
                  subContainerClassName={"pages pagination"}
                  breakClassName={"break-me"}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  breakLabel={"..."}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={dropdownOpen}
        toggle={toggle}
        className="modal-view modal-lg modal-dialog-centered"
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        {mdata !== null ? (
          <ModalBody>
            <div className="success-screen">
              <div className="thank-you text-center">
                <h1 className="text-white">Payment Information</h1>
                <strong className="text-white">
                  Transaction ID:{mdata.id}
                </strong>
              </div>
              <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
                <Row>
                  <Col lg={6}>
                    <h6>Customer Information</h6>
                    <ul className="list-unstyled mb-0">
                      <li>{mdata.customerName}</li>
                    </ul>
                  </Col>
                  <Col lg={6} className="text-lg-right mt-4 mt-lg-0">
                    <h6>Summary</h6>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <span>Order ID:</span> <strong>{mdata.id}</strong>
                      </li>
                      <li>
                        <span>Order Date:</span>{" "}
                        <strong>{mdata.saleDate}</strong>
                      </li>
                      <li>
                        <span>Order Total:</span>{" "}
                        <strong>
                          {"\u20AC"}
                          {mdata.totalPrice}.00
                        </strong>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="ordered-detail">
                <div className="table-responsive">
                  <table className="table total-table table-borderless mt-4 mb-0">
                    <tbody>
                      <tr>
                        <td>Total Price</td>
                        <td className="text-right">
                          {"\u20AC"}
                          {mdata.totalPrice}.00
                        </td>
                      </tr>
                      <tr>
                        <td>Paid Amount</td>
                        <td className="text-right">
                          {"\u20AC"}
                          {mdata.paidAmount}.00
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td className="text-right">{mdata.status}</td>
                      </tr>
                      <tr className="border-top">
                        <td>
                          <strong className="h5">Due Amount </strong>
                        </td>
                        <td className="text-right h5">
                          <strong>
                            {"\u20AC"}
                            {mdata.totalPrice - mdata.paidAmount}.00
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
        ) : null}
      </Modal>
    </>
  );
}
export default Sales;
