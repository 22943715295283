import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'store/actions'
import { Row, Col,Container, Table} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Loader from 'react-loader-spinner';
import history from '@history'
import { Link } from 'react-router-dom';

function DmgGoods(){
    const dispatch = useDispatch()
    const [expense, setExpense] = useState(null)
    const [expCount, setExpCount] = useState(null)
    const [page, setPage] = useState(1)
    const Exp = useSelector(({app}) => app.Expense)
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        if(Auth){
            dispatch(Actions.getdmgGoods(page))
        }
    },[dispatch,page,Auth])
    useEffect(() => {
        setExpense(Exp.rows)
        setExpCount(Exp.totalExp)
    },[Exp])
    const paginate = (e) => {
        let pg = e.selected +1
        setPage(pg)
      }
      const delbtn = id => {
        dispatch(Actions.deleteDmgGoods(id))
      }
    if(Auth === false){
        history.push({
            pathname: '/'
        })
    }

return(
    <div className="section-ptb">
      <Container className="themed-container" fluid="md">
        <Row>
        <Col lg={12}>
            <div className="mb-0">
              <h4>Damage Goods List</h4>
            </div>
            <div style={{overflowX: 'auto'}}>
              <Table striped style={{minWidth: '800px'}}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Amount</th>
                    <th>Product Name</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {expense === null ?
                <div id="preloader">
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
                </div> : expense.length === 0 ?
                <tbody><tr><th colSpan={7} className='text-center'>No Damage Goods Found!</th></tr></tbody>:
                <tbody>
                  {expense.map(cust => (
                    <tr key={cust.id}>
                      <td>{cust.id}</td>
                      <td>{cust.amount}</td>
                      <td>{cust.productName}</td>
                      <td>{cust.created_at}</td>
                      <td><Link className="delete-button" to ='#' onClick={e => delbtn(cust.id)}><i className="fa fa-trash-o pl-2"></i></Link></td>
                    </tr>
                  ))}
                </tbody>
                }
              </Table>
          </div>
          </Col>
        </Row>
        <Row>
          <Col>
          {expCount && expCount >10 ?
          <ReactPaginate
            pageCount = {expCount/10}
            initialPage = {0}
            onPageChange = {e => paginate(e)}
            activeClassName={'active'}
            containerClassName={'pagination332'}
            subContainerClassName={'pages pagination'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            breakLabel={'...'}
          />
          : <></>}
          </Col>
        </Row>
      </Container>
    </div>
)
}
export default DmgGoods;