import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
export const GET_CATEGORIES = '[APP] GET_CATEGORIES';
export const ADD_CATEGORY = '[APP] ADD_CATEGORY';
export const DELETE_CATEGORY = '[APP] DELETE_CATEGORY';

export function getCategories() {
    const request = axios.get(`${Domain}/getCategories`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_CATEGORIES,
                payload: response.data.result
            })
        }
        )
    )
}
export function addCategory(data) {
    const request = axios.post(`${Domain}/addCategory`,data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_CATEGORY,
            })
        }
        ).then(() => toast.success('Category Saved'))
        .then(() => dispatch(getCategories()))
        .catch(error => {
            console.log(error)
            toast.warn('Cannot save Category')
        })
    )
}
export function deleteCategory(id) {
    const request = axios.delete(`${Domain}/deleteCategory`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: DELETE_CATEGORY,
            })
        }
        ).then(() => toast.success('Category Deleted'))
        .then(() => dispatch(getCategories()))
        .catch(error => {
            console.log(error)
            toast.warn('Sorry! Category in use')
        })
    )
}
