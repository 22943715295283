import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from 'store/actions'
import Loader from 'react-loader-spinner';
import { Row, FormGroup, Input, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

function EditCustomer(props){
    const dispatch = useDispatch()
    const custId = parseInt(props.match.params.id);
    const [cust, setCust] = useState(null)
    const CustomerData = useSelector(({app}) => app.Customers.customerDetails)
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(Actions.customerDetails(custId))
    },[dispatch,custId])
    useEffect(() => {
        setCust(CustomerData)
    },[CustomerData])
    const formChange = (e) => {
        setCust({
            ...cust,
            [e.target.name] : e.target.value
        })
    }
    const formSubmit = (e) => {
        e.preventDefault()
        dispatch(Actions.updateCustomer(cust))
    }
    if(cust === null || cust === undefined){
        return (
            <div id="preloader">
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
            )
          }
    return(
        <div className="site-content">
            <div className="content-wrapper section-ptb">
                <Container>
                    <h2>Edit Customer</h2>
                <Row>
                    <div className="product-top-right col-xl-7 col-md-6">
                        <div className="product-top-right-inner">
                            <form onSubmit={e => formSubmit(e)}>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'fullName' placeholder="Customer Name" onChange= {e=> formChange(e)} value= {cust.fullName} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'phone' placeholder="Phone" onChange= {e=> formChange(e)} value= {cust.phone} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'address' placeholder="Address" onChange= {e=> formChange(e)} value= {cust.address} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input  type="textarea" name = 'detail' rows="3" placeholder="Details(Optional)" onChange= {e=> formChange(e)}  value= {cust.detail}/>
                                    </FormGroup>
                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                    <Link to="/customers" className="btn btn-danger mb-2"> Cancel </Link>
                            </form>
                        </div>
                    </div>
                </Row>
                </Container>
            </div>
        </div>
    )
}
export default EditCustomer;