import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
export const GET_PRODUCTS = '[APP] GET_PRODUCTS';
export const EMPTY_PRODUCTS = '[APP] EMPTY_PRODUCTS';
export const SET_LOADING = '[APP] SET_LOADING';
export const PRODUCT_DETAILS = '[APP] PRODUCT_DETAILS';
export const ADD_PRODUCT = '[APP] ADD_PRODUCT';
export const UPDATE_PRODUCT = '[APP] UPDATE_PRODUCT';
export const DELETE_PRODUCT = '[APP] DELETE_PRODUCT';


export function emptyProduct(){
    return {
        type: EMPTY_PRODUCTS,
    }
}
export function setLoading(data){
    return {
        type: SET_LOADING,
        payload: data
    }
}

export function getProducts(page) {
    const request = axios.post(`${Domain}/getProducts/latest`, {page, limit :12});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTS,
                payload: response.data.result
            })
        }
        ).then(dispatch(setLoading(false)))
    )
}
export function SearchProducts(page,SearchValue,catId) {
    const request = axios.post(`${Domain}/getProducts/category`, {page,SearchValue,catId});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTS,
                payload: response.data.result
            })
        }
        )
    )
}

export function productDetails(id) {
    const request = axios.get(`${Domain}/productDetails`, {
        params: {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: PRODUCT_DETAILS,
                payload: response.data.result
            })
        }
        )
    )
}
export function addProduct(data) {
    const request = axios.post(`${Domain}/addProduct`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_PRODUCT,
            })
        })
        .then(() => dispatch(setLoading(false)))
        .then(() => toast.success('Product Added successfully'))
        .catch(error => {
            console.log(error)
            toast.warn('Cannot add product')
        })
    )
}
export function updateProduct(data,id) {
    const request = axios.put(`${Domain}/updateproduct`, data, {params : {id}});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: UPDATE_PRODUCT,
            })
        }
        ).then(() => dispatch(productDetails(id)))
        .then(() => toast.success('Product updated successfully'))
        .catch(error => {
            console.log(error)
            toast.warn('Cannot update product')
        })
    )
}
export function deleteProduct(id) {
    const request = axios.put(`${Domain}/deleteProduct`, {id});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: DELETE_PRODUCT,
            })
        }
        ).then(() => window.location.reload())
        .then(() => toast.success('Product deleted successfully'))
        .catch(error => {
            console.log(error)
            toast.warn('Cannot delete product')
        })
    )
}
