import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Row, FormGroup, Input, Container } from 'reactstrap';
import * as Actions from 'store/actions'
import { Link } from 'react-router-dom';
import moment from 'moment';

function AddExpense(props){
    const dispatch = useDispatch()
    let datenow = Date.now()
    let thisdate = moment(datenow).format('YYYY-MM-DD')
    const initStg = {
        expenseDate : thisdate,
        category : '',
        expenseFor : '',
        amount : '',
    }
    const [formData, setFormData] = useState(initStg)
    const formChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const formSubmit = (e) => {
        e.preventDefault()
        dispatch(Actions.addExpense(formData))
        setFormData({expenseDate : thisdate, category : '', expenseFor : '', amount : ''})
    }
    return(
        <div className="site-content">
            <div className="content-wrapper section-ptb">
                <Container>
                    <h2>Add Expense</h2>
                <Row>
                    <div className="product-top-right col-xl-7 col-md-6">
                        <div className="product-top-right-inner">
                            <form onSubmit={e => formSubmit(e)}>
                                    <FormGroup className="edit-icon">
                                        <Input type="date" name = 'expenseDate' placeholder="expenseDate" value={formData.expenseDate} onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="text" name = 'category' placeholder="Category" onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input type="textarea" name = 'expenseFor' rows="3" placeholder="Details(Optional)" onChange= {e=> formChange(e)} />
                                    </FormGroup>
                                    <FormGroup className="edit-icon">
                                        <Input  type="number" min= {1} name = 'amount' placeholder="Amount" onChange= {e=> formChange(e)} required/>
                                    </FormGroup>
                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                    <Link to="/customers" className="btn btn-danger mb-2"> Cancel </Link>
                            </form>
                        </div>
                    </div>
                </Row>
                </Container>
            </div>
        </div>
    )

}

export default AddExpense;